type TLanguages = 'tr' | 'en' | 'ar'

export type TProduct = {
  id?: string | number
  brand_id: any
  category_id: string
  order: number | string
  vat: number | string
  product_id: string | string[]
  mp_code: string
  gtip_code: string
  desi: string
  stock_code: string
  link: string
  is_active: boolean
  contents: Array<{
    language: string
    description: string
    name: string
    slug: string
    body: string
    seo_headline: string
    seo_tags: string
  }>
  sub_products: Array<any>
  categories: Array<string | number>
  colors: any[]
  variations: any[]
}

type TContents =
  | any
  | Array<{
      language: TLanguages
      name: string
      description?: string
      slug: string
      body: string
      seo_headline: string
      seo_tags: string
    }>

type TColors = Array<{
  color_name: string
  color_code: string
  product_color_id: string
  color_id: string
  stock_code: string
  tags: Array<string | number>
  badges: Array<string | number>
  contents: TContents
  media: any
  orders: Array<any>
  variations: Array<any>
  lowest_stock_buy_price: string
  lowest_stock_sell_price: string
}>

export const initialContents: TContents = [
  {
    language: 'tr',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
  {
    language: 'ar',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
  {
    language: 'en',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
]

export type TUpdateProduct = {
  id: string
  brand_id: any
  category_id: string
  mp_code: string
  gtip_code: string
  desi: string
  is_active: boolean
  categories: string[]
  contents: TContents
  colors: TColors
}

export const initialUpdateProduct: TUpdateProduct = {
  id: '',
  brand_id: '',
  category_id: '',
  mp_code: '',
  gtip_code: '',
  desi: '',
  is_active: true,
  categories: [],
  contents: initialContents,
  colors: [],
}

export const initialProduct: TProduct = {
  order: 0,
  vat: 8,
  sub_products: [],
  brand_id: '',
  product_id: '',
  category_id: '',
  mp_code: '',
  gtip_code: '',
  desi: '',
  is_active: true,
  categories: [],
  colors: [],
  variations: [],
  link: '',
  stock_code: '',
  contents: initialContents,
}

export type TUpdateStock = {
  id?: string
  variation_code: string
  quantity: string
  buy_price: string
  sell_price: string
  barcode: string
  preparing_duration: number | null
}

export const initialUpdateStock: TUpdateStock = {
  variation_code: '',
  quantity: '',
  buy_price: '',
  sell_price: '',
  barcode: '',
  preparing_duration: null,
}

export interface IProducts {
  productsFilterOptions: Array<any>
  productsList: Array<TProduct>
  showFilterDrawer: boolean
  showStockModal: boolean
  stock: TUpdateStock
  showManageModal: boolean
  showDeleteModal: boolean
  showDeleteStockModal: boolean
  product: TProduct
  productNestedList: any[]
  dataForCreate: TUpdateProduct
  depth: {
    level: number
    url: string
  }
  productsLoading: boolean
  showSortProductsModal: boolean
  showUpdateSellPriceModal: boolean
  variationCode: string
}

export interface IProductsObject {
  products: IProducts
}

export const initialState: IProducts = {
  // Filters
  productsFilterOptions: [],
  // Create
  dataForCreate: initialUpdateProduct,
  // Data
  product: initialProduct,
  productsList: [],
  // Filter Drawer
  showFilterDrawer: false,
  // Modals
  showManageModal: false,
  showSortProductsModal: false,
  showStockModal: false,
  showDeleteModal: false,
  showDeleteStockModal: false,
  // Nested
  productNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
  productsLoading: false,
  variationCode: '',
  stock: initialUpdateStock,
  showUpdateSellPriceModal: false,
}
