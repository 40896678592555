import {TContents, TFilterOption, TTableNestedItem} from 'models'
import {initialContents} from '../../../constants'

type TProductColor = {
  id: number
  contents: TContents
}

type TLot = {
  variation_id: number
  quantity: number
  variation: {
    id: number
    is_active: boolean
    order: string
    contents: TContents
  }
}

export type TWholesaleProduct = {
  id: number
  product_color_id: number
  product_color: TProductColor
  price_of_each_item: string
  lots: Array<TLot>
}

export const initialWholesaleProduct: TWholesaleProduct = {
  id: 0,
  product_color_id: 0,
  product_color: {
    id: 0,
    contents: initialContents,
  },
  price_of_each_item: '',
  lots: [],
}

export interface IWholesaleProducts {
  wholesaleProductsFilterOptions: Array<TFilterOption>
  wholesaleProductsList: Array<TWholesaleProduct>
  showFilterDrawer: boolean
  showManageModal: boolean
  showDeleteModal: boolean
  wholesaleProduct: TWholesaleProduct
  wholesaleProductNestedList: Array<TTableNestedItem>
  depth: {
    level: number
    url: string
  }
  wholesaleProductsLoading: boolean
}

export interface IWholesaleProductsObject {
  wholesaleProducts: IWholesaleProducts
}
