export type TVariation = {
  id?: string | number
  order: number | string
  is_active: boolean
  name: string
  contents: Array<{
    language: string
    name: string
    body: string
    number: string
  }>
}

export const initialVariation: TVariation = {
  name: '',
  order: 0,
  is_active: true,
  contents: [
    {
      language: 'tr',
      name: '',
      body: '',
      number: '',
    },
    {
      language: 'ar',
      name: '',
      body: '',
      number: '',
    },
    {
      language: 'en',
      name: '',
      body: '',
      number: '',
    },
  ],
}

export interface IVariation {
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  variationList: Array<TVariation>
  variationsFilterOptions: Array<any>
  variation: TVariation
}

export interface IVariationObject {
  variation: IVariation
}

export const initialState: IVariation = {
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  variationList: [],
  variationsFilterOptions: [],
  variation: initialVariation,
}
