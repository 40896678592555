export type TProductColor = {
  id?: string | number
  contents: Array<{
    language: string
    name: string
  }>
}

export const initialProductColor: TProductColor = {
  contents: [
    {
      language: 'tr',
      name: '',
    },
    {
      language: 'ar',
      name: '',
    },
    {
      language: 'en',
      name: 'e',
    },
  ],
}

export interface IProductColors {
  productColorsFilterOptions: Array<any>
  productColorsList: Array<TProductColor>
  productColor: TProductColor
  showFilterDrawer: boolean
  showManageModal: boolean
  showDeleteModal: boolean
  showProductColorUpdatePrice: boolean
  depth: {
    level: number
    url: string
  }
  productColorsLoading: boolean
  productColorNestedList: any[]
}

export interface IProductColorsObject {
  productColors: IProductColors
}

export const initialState: IProductColors = {
  // Filters
  productColorsFilterOptions: [],
  // Data
  productColor: initialProductColor,
  productColorsList: [],
  // Filter Drawer
  showFilterDrawer: false,
  // Modals
  showDeleteModal: false,
  showManageModal: false,
  showProductColorUpdatePrice: false,
  // Nested
  productColorNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
  productColorsLoading: false,
}
