type Props = {
  handleClose: () => void
  title: string
}

const Header = ({handleClose, title}: Props) => {
  return (
    <div className='modal-header d-flex flex-column border-bottom-0'>
      <div className='d-flex w-100 justify-content-between align-items-center'>
        <h2>{title}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
        <i className="fa-solid fa-xmark fs-2"/>
        </div>
      </div>
    </div>
  )
}

export default Header
