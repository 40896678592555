import {createSlice} from '@reduxjs/toolkit'
import {ICategoriesObject, initialCategory, initialState} from './_models'

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoriesList: (state, action) => {
      const {data} = action.payload
      state.categoriesList = data
    },
    setCategoriesFilterOptions: (state, action) => {
      const {data} = action.payload
      state.categoriesFilterOptions = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.category = initialCategory
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedCategory: (state, action) => {
      const {data} = action.payload
      state.category = data
    },
    setNestedCategoriesDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setCategoriesLoading: (state, action) => {
      const {loading} = action.payload
      state.categoriesLoading = loading
    },
    setNestedCategories: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const isExist = state.categoryNestedList.findIndex(
        (e: any) => e.id === id && e.level === level
      )
      if (isExist === -1) {
        const updatedCategoryNestedList = [...state.categoryNestedList]
        updatedCategoryNestedList.push(newItem)
        state.categoryNestedList = updatedCategoryNestedList
      }
    },
  },
})

export default categoriesSlice.reducer

export const {
  setShowDeleteModal,
  setCategoriesList,
  setShowManageModal,
  setSelectedCategory,
  setShowFilterDrawer,
  setCategoriesFilterOptions,
  setNestedCategories,
  setNestedCategoriesDepth,
  setCategoriesLoading,
} = categoriesSlice.actions

export const selectShowManageModal = (state: ICategoriesObject) => state.categories.showManageModal
export const selectCategoriesList = (state: ICategoriesObject) => state.categories.categoriesList
export const selectCategory = (state: ICategoriesObject) => state.categories.category
export const selectCategoriesFilterOptions = (state: ICategoriesObject) =>
  state.categories.categoriesFilterOptions
export const selectShowFilterDrawer = (state: ICategoriesObject) =>
  state.categories.showFilterDrawer
export const selectShowDeleteModal = (state: ICategoriesObject) => state.categories.showDeleteModal
export const selectCategoriesLoading = (state: ICategoriesObject) =>
  state.categories.categoriesLoading
export const selectCategoryNestedList = (state: ICategoriesObject) =>
  state.categories.categoryNestedList
export const selectCategoryNestedDepth = (state: ICategoriesObject) => state.categories.depth
