import {createSlice} from '@reduxjs/toolkit'
import {IMenusObject, initialMenu, initialState} from './_models'

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenusList: (state, action) => {
      const {data} = action.payload
      state.menusList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.menu = initialMenu
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedMenu: (state, action) => {
      const {data} = action.payload
      state.menu = data
    },
    setShowMediaModal: (state, action) => {
      const {show} = action.payload
      state.showMediaModal = show
    },
    addMenuSettings: (state, action) => {
      const {data} = action.payload
      const newMenuSettings = [...state.menuSettings]
      newMenuSettings.push(data)
      state.menuSettings = newMenuSettings
    },
    reorderMenuSettings: (state, action) => {
      const {data} = action.payload
      state.menuSettings = data
    },
    resetMenuSettings: (state) => {
      state.menuSettings = []
    },
    setMenusFilterOptions: (state, action) => {
      const {data} = action.payload
      state.menusFilterOptions = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
  },
})

export default menusSlice.reducer

export const {
  setShowMediaModal,
  setShowDeleteModal,
  setMenusList,
  setSelectedMenu,
  addMenuSettings,
  reorderMenuSettings,
  resetMenuSettings,
  setShowManageModal,
  setMenusFilterOptions,
  setShowFilterDrawer,
} = menusSlice.actions

export const selectShowMediaModal = (state: IMenusObject) => state.menus.showMediaModal
export const selectShowDeleteModal = (state: IMenusObject) => state.menus.showDeleteModal
export const selectShowManageModal = (state: IMenusObject) => state.menus.showManageModal
export const selectMenuSettings = (state: IMenusObject) => state.menus.menuSettings
export const selectMenusList = (state: IMenusObject) => state.menus.menusList
export const selectMenu = (state: IMenusObject) => state.menus.menu
export const selectMenusFilterOptions = (state: IMenusObject) => state.menus.menusFilterOptions
export const selectShowFilterDrawer = (state: IMenusObject) => state.menus.showFilterDrawer
