import {FC, useState} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useIntl} from 'react-intl'
import {reorderByIndex} from 'utils/array'

type Props = {
  columns: any
  tableId: string | undefined
  allHiddenFields: any
  onSubmitHandler: any
}

const ColumnsAdjustment: FC<Props> = ({columns, tableId, allHiddenFields, onSubmitHandler}) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <div onClick={() => setShow(true)}>
        <i className='fa-solid fa-gear text-white' />
      </div>
      {show && (
        <ColumnsAdjustmentModal
          show={show}
          handleClose={() => setShow(false)}
          columns={columns}
          tableId={tableId}
          allHiddenFields={allHiddenFields}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </>
  )
}

export default ColumnsAdjustment

const modalsRoot = document.getElementById('root-modals') || document.body

const ColumnsAdjustmentModal = ({
  show,
  handleClose,
  columns,
  tableId,
  allHiddenFields,
  onSubmitHandler,
}: any) => {
  const [allColumns, setAllColumns] = useState(columns)
  const [hiddenFields, setHiddenFields] = useState<Array<string>>(allHiddenFields)

  const handleSubmit = () => {
    localStorage.setItem(
      tableId,
      JSON.stringify({
        hiddenFields: hiddenFields,
        allColumnsOrder: allColumns.map((column: any) => column.header),
      })
    )
    handleClose()
    onSubmitHandler()
  }

  const handleChange = (evt: any, headerTitle: string) => {
    const newArray = [...allColumns]
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].header === headerTitle) {
        newArray[i].isHidden = !evt.target.checked
        break
      }
    }

    setAllColumns(newArray)
    if (!evt.target.checked) {
      setHiddenFields([...hiddenFields, headerTitle])
    } else {
      setHiddenFields((prevState: any) => {
        return prevState.filter((item: string) => item !== headerTitle)
      })
    }
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    if (result.type === 'droppableItem') {
      const items = reorderByIndex(allColumns, sourceIndex, destIndex)
      setAllColumns(items)
    }
  }

  const getSectionStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? '#FFF8DD' : '#fff',
  })

  const getSectionItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? '#C8F5DD' : '',
    ...draggableStyle,
  })
  const intl = useIntl()

  return createPortal(
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered mw-400px`}
      onHide={handleClose}
      show={show}
      backdrop
    >
      <div className='modal-header d-flex align-items-center px-6 py-4'>
        <span className='fw-bold fs-4'>{intl.formatMessage({id: 'COLUMNS_ADJUSTMENT'})}</span>
        <button className='btn btn-icon' onClick={handleClose}>
          <i className='fa-solid fa-xmark fs-3'></i>
        </button>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='droppable' type='droppableItem'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className='d-flex flex-column gap-5 h-300px overflow-auto px-2'
                style={getSectionStyle(snapshot.isDraggingOver)}
              >
                {allColumns.map((column: any, columnIndex: number) => (
                  <Draggable key={column.header} draggableId={column.header} index={columnIndex}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className='d-flex justify-content-between align-items-center p-1'
                        style={getSectionItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div>{intl.formatMessage({id: column.header})}</div>
                        <div className='d-flex'>
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={String(!column.isHidden)}
                              onChange={(evt) => handleChange(evt, column.header)}
                              defaultChecked={!column.isHidden}
                            />
                          </div>
                          <div {...provided.dragHandleProps} className='ms-3'>
                            <span className='badge badge-secondary text-white'>
                              {intl.formatMessage({id: 'DRAG'})}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className='d-flex justify-content-end gap-3 mt-15'>
          <button className='btn btn-danger btn-sm' onClick={handleClose}>
            {intl.formatMessage({id: 'CANCEL'})}
          </button>
          <button className='btn btn-primary btn-sm' onClick={handleSubmit}>
            {intl.formatMessage({id: 'SUBMIT'})}
          </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}
