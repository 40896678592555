export type TCart = {
  user_id: number | null
  user_type: string | null
  total_quantity: number
  total_price: number
  first_product_added_date: string | null
  last_product_added_date: string | null
}

export const initialCard: TCart = {
  user_id: null,
  user_type: '',
  total_quantity: 1,
  total_price: 1,
  first_product_added_date: '',
  last_product_added_date: '',
}

export interface ICart {
  showFilterDrawer: boolean
  showManageModal: boolean
  cartList: Array<TCart>
  cart: any
  cartFilterOptions: Array<any>
  depth: {
    level: number
    url: string
  }
  cartsLoading: boolean
  cartNestedList: any[]
}

export interface ICartObject {
  carts: ICart
}

export const initialState: ICart = {
  showManageModal: false,
  showFilterDrawer: false,
  cartList: [],
  cart: initialCard,
  cartFilterOptions: [],
  cartsLoading: false,
  cartNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
}
