import {configureStore} from '@reduxjs/toolkit'
import MediaSliceReducer from 'components/MediaModal/core/slice'
import AuthReducer from 'features/auth/authSlice'
import BadgesReducer from 'pages/badges/core/slice'
import CurrenciesReducer from 'pages/currencies/core/slice'
import CartsReducer from 'pages/cart-List/core/slice'
import CategoriesReducer from 'pages/categories/core/slice'
import ColorsReducer from 'pages/colors/core/slice'
import DailySellReducer from 'pages/daily-sell/core/slice'
import MenusReducer from 'pages/menus/core/slice'
import OrdersReducer from 'pages/order-list/core/slice'
import PagesReducer from 'pages/pages/core/slice'
import ProductColorsReducer from 'pages/product-colors/core/slice'
import ProductsReducer from 'pages/products/core/slice'
import PromotionsReducer from 'pages/promotions/core/slice'
import TagsReducer from 'pages/tags/core/slice'
import UsersReducer from 'pages/users/core/slice'
import VariationReducer from 'pages/variation/core/slice'
import CouponReducer from 'pages/coupons/core/slice'
import WholesaleProductsReducer from 'pages/wholesale-products/core/slice'
import SaleChannelsRaducer from 'pages/sale-channels/core/slice'
import {apiSlice} from './api/apiSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: AuthReducer,
    media: MediaSliceReducer,
    categories: CategoriesReducer,
    colors: ColorsReducer,
    users: UsersReducer,
    products: ProductsReducer,
    wholesaleProducts: WholesaleProductsReducer,
    productColors: ProductColorsReducer,
    pages: PagesReducer,
    variation: VariationReducer,
    badges: BadgesReducer,
    tags: TagsReducer,
    menus: MenusReducer,
    orders: OrdersReducer,
    carts: CartsReducer,
    dailySell: DailySellReducer,
    promotions: PromotionsReducer,
    currencies: CurrenciesReducer,
    saleChannels: SaleChannelsRaducer,
    coupons: CouponReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})

export type RootState = ReturnType<typeof store.getState>
