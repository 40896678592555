export type TDailySell = {}

export const initialDailySell: TDailySell = {}

export interface IDailySellTypes {
  dailySellFilterOptions: Array<any>
  showFilterDrawer: boolean
  dailySellList: Array<any>
}

export interface IDailySellTypesObject {
  dailySell: IDailySellTypes
}

export const initialState: IDailySellTypes = {
  dailySellFilterOptions: [],
  showFilterDrawer: false,
  dailySellList: [],
}
