/**
 * @summary Search the array for an object with the specified @argument itemId.
 * If not found at the first level, recursively search through its children until found.
 */
export function findObjectById(array, itemId) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === itemId) {
      return array[i]
    } else if (array[i].sub_categories && array[i].sub_categories.length > 0) {
      const result = findObjectById(array[i].sub_categories, itemId)
      if (result) {
        return result
      }
    }
  }
  return null
}

export const reorderByIndex = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * @argument array1 is the array you want to reorder the elements
 * @argument array2 is the string array you want to reorder the first array with their elements place
 */
export const reorderByArray = (array1, array2) => {
  if (array2.length === 0) {
    return array1
  }

  return array2.map((item) => {
    return array1.find((obj) => obj.Header === item)
  })
}

export const getLastStringOfArray = (arr) => {
  if (arr.length > 0) {
    return arr[arr.length - 1]
  } else {
    return null
  }
}
