export type TOrder = {
  id?: string | number
  contents: Array<{
    language: string
    name: string
  }>
}

export const initialOrderType: TOrder = {
  contents: [
    {
      language: 'tr',
      name: '',
    },
    {
      language: 'ar',
      name: '',
    },
    {
      language: 'en',
      name: '',
    },
  ],
}

export interface IOrderTypes {
  ordersFilterOptions: Array<any>
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  orderList: Array<TOrder>
  order: TOrder
}

export interface IOrderTypesObject {
  orders: IOrderTypes
}

export const initialState: IOrderTypes = {
  ordersFilterOptions: [],
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  orderList: [],
  order: initialOrderType,
}
