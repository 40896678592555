import {RenderIf} from 'helpers'

type Props = {
  headerTitle: string
  bodyTitle: string
  errorsList: {key: string; value: string}[]
}

const Notification = ({headerTitle, bodyTitle, errorsList}: Props) => {
  return (
    <div className='w-100 alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10'>
      <i className='fa-solid fa-bell fs-2 me-4 mb-5 mb-sm-0' />
      <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
        <h5 className='mb-1 text-wrap text-break'>{headerTitle}</h5>
        <span className='text-wrap text-break'>{bodyTitle}</span>
        <RenderIf isTrue={errorsList.length > 0}>
          <ul className='text-dark mt-3'>
            {errorsList?.map((item) => (
              <li>
                <span className='fw-bold'>{`${item.key}: `}</span>
                <span>{item.value}</span>
              </li>
            ))}
          </ul>
        </RenderIf>
      </div>
      <button
        type='button'
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
        data-bs-dismiss='alert'
      >
        <i className='fa-solid fa-xmark fs-2' />
      </button>
    </div>
  )
}

export default Notification
