export type TTag = {
  id?: string | number
  name: string
  order: number
}

export const initialTag: TTag = {
  name: '',
  order: 1,
}

export interface ITag {
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  tagsList: Array<TTag>
  tag: TTag
  tagsFilterOptions: Array<any>
}

export interface ITagObject {
  tags: ITag
}

export const initialState: ITag = {
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  tagsList: [],
  tag: initialTag,
  tagsFilterOptions: [],
}
