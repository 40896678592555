import {createSlice} from '@reduxjs/toolkit'
import {setAuth, setUser, getAuth, getUser, removeAuth, removeUser} from 'helpers'
import {AuthModel} from 'models/auth.model'
interface IAuth {
  user: any | null
  token: AuthModel | null | string
}

interface IAuthObject {
  auth: IAuth
}

const initialState: IAuth = {
  user: getUser() ?? null,
  token: getAuth() ?? null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const {user, accessToken} = action.payload
      state.user = user
      state.token = accessToken
      setAuth(accessToken)
      setUser(user)
    },
    logout: (state, _action) => {
      state.user = null
      state.token = null
      removeAuth()
      removeUser()
    },
  },
})

export const {setCredentials, logout} = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: IAuthObject) => state.auth.user
export const selectCurrentToken = (state: IAuthObject) => state.auth.token
