import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  isSubmitting: boolean
  details?: any
  disabled?: boolean
} & React.HTMLAttributes<HTMLButtonElement>

const FormSubmitButton: FC<Props> = ({isSubmitting, details, disabled, ...restProps}) => {
  const intl = useIntl()
  return (
    <div className='text-center pt-5'>
      <button
        {...restProps}
        type='submit'
        className='btn btn-primary'
        data-categories-modal-action='submit'
        disabled={isSubmitting || disabled}
      >
        {!isSubmitting && (
          <span className='indicator-label'>
            {details?.id !== undefined
              ? intl.formatMessage({id: 'UPDATE'})
              : intl.formatMessage({id: 'CREATE'})}
          </span>
        )}
        {isSubmitting && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  )
}

export default FormSubmitButton
