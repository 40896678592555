import {Languages} from 'components/Languages'
import {logout, selectCurrentToken, selectCurrentUser} from 'features/auth/authSlice'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const token = useSelector(selectCurrentToken)
  const tokenAbbr = `${String(token)?.slice(0, 9)}...`
  const intl = useIntl()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {`${user.name} ${user.surname}`}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {tokenAbbr}
              </span>
            </div>
            <span className='fw-bold text-muted text-hover-primary fs-7'>{user.email}</span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <div onClick={() => dispatch(logout({}))} className='menu-link px-5'>
         {intl.formatMessage({id: 'SIGN_OUT'})}
        </div>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
