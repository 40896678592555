import MediaModal from 'components/MediaModal/MediaModal'
import {onClear} from 'components/MediaModal/core/slice'
import InputField from 'components/common/InputField/InputField'
import {Formik} from 'formik'
import {RenderIf} from 'helpers'
import {TMediaData} from 'models'
import {
  deleteShape,
  selectSelectedBoxForEdit,
  selectShowMediaModal,
  setShowBoxSettings,
  setShowMediaModal,
  updateSelectedBoxForEdit,
} from 'pages/pages/core/slice'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

const UploadBox = ({dispatch}: {dispatch: any}) => (
  <div
    style={{
      background: 'rgba(245, 248, 250, 0.4)',
      border: '1px dashed #B5B5C3',
      borderRadius: '9px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    className='cursor-pointer p-5'
    onClick={() => dispatch(setShowMediaModal({show: true}))}
  >
    <span className='fw-bolder fs-7 text-gray-600'>Add Image From Library</span>
  </div>
)

const BoxSettings = () => {
  const intl = useIntl()
  const showMediaModal = useSelector(selectShowMediaModal)
  const details = useSelector(selectSelectedBoxForEdit)
  const dispatch = useDispatch()

  return (
    <div className='w-300px d-flex flex-column shadow-sm p-5 bg-white sticky-top'>
      <div className='d-flex justify-content-between align-items-center mt-3 text-center'>
        <button
          className='btn btn-icon btn-sm'
          onClick={() => {
            dispatch(setShowBoxSettings({show: false}))
          }}
        >
          <i className='fa-solid fa-arrow-left fs-2' />
        </button>
        <h3 style={{color: '#3F4254', fontSize: '1rem'}}>{intl.formatMessage({id: 'SETTINGS'})}</h3>
        <div />
      </div>
      <div className='border-top border-gray-300 w-100 my-3' />
      <div className='mt-3 d-flex justify-content-between align-items-center'>
        <h3 style={{color: '#3D3D3D', fontSize: '1rem'}}>
          {intl.formatMessage({id: 'BOX_WIDGET'})}
        </h3>
        <button
          className='btn btn-icon btn-danger btn-sm'
          onClick={() => {
            dispatch(deleteShape({id: details.id, type: 'box'}))
          }}
        >
          <i className='fa-solid fa-trash fs-2' />
        </button>
      </div>
      <div className='border-top border-gray-300 w-100 my-3' />
      <Formik initialValues={details} enableReinitialize onSubmit={() => {}}>
        {({values, handleChange, handleBlur, handleSubmit, setFieldValue}) => {
          dispatch(updateSelectedBoxForEdit({data: values}))
          return (
            <>
              <form className='form' onSubmit={handleSubmit}>
                <div className='fv-row mb-6'>
                  <InputField
                    label={intl.formatMessage({id: 'LABEL'})}
                    placeholder={intl.formatMessage({id: 'ENTER_LABEL'})}
                    value={values.title}
                    name='title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='mb-3 w-100'
                  />
                </div>
                <div className='fv-row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 d-block'>
                    {intl.formatMessage({id: 'IMAGE'})}
                  </label>
                  <RenderIf isTrue={Boolean(values.media)}>
                    <img className='rounded my-3' width={200} src={values.media} alt='' />
                  </RenderIf>
                  <UploadBox dispatch={dispatch} />
                </div>
                <div className='fv-row mb-6'>
                  <InputField
                    label={intl.formatMessage({id: 'URL'})}
                    placeholder={intl.formatMessage({id: 'LINK'})}
                    value={values.link}
                    name='link'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='mb-3 w-100'
                  />
                </div>
              </form>
              <MediaModal
                isMulti={false}
                show={showMediaModal}
                handleClose={() => {
                  dispatch(setShowMediaModal({show: false}))
                }}
                handleSubmit={(data: TMediaData) => {
                  dispatch(setShowMediaModal({show: false}))
                  setFieldValue('media', data.conversions.original)
                  dispatch(onClear())
                }}
              />
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default BoxSettings
