import {TFilterOption} from 'models'

export type TCoupon = {
  id?: number
  type: string
  code: string
  min_cart_total_price: string
  discount_amount: string
  is_active: boolean
  start_at: string
  end_at: string
  usage_limitation_count: number
}

export const initialCoupon: TCoupon = {
  type: 'public',
  code: '',
  min_cart_total_price: '',
  discount_amount: '',
  is_active: true,
  start_at: '',
  end_at: '',
  usage_limitation_count: 0,
}

export interface ICoupon {
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  couponsList: Array<TCoupon>
  coupon: TCoupon
  couponsFilterOptions: Array<TFilterOption>
}

export interface ICouponObject {
  coupons: ICoupon
}

export const initialState: ICoupon = {
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  couponsList: [],
  coupon: initialCoupon,
  couponsFilterOptions: [],
}
