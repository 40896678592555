import {createSlice} from '@reduxjs/toolkit'
import {ICartObject, initialCard, initialState} from './_models'

const cartSlice = createSlice({
  name: 'cartList',
  initialState,
  reducers: {
    setCartList: (state, action) => {
      const {data} = action.payload
      state.cartList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.cart = initialCard
      }
    },
    setSelectedCart: (state, action) => {
      const {show} = action.payload
      state.cart = show
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setCartFilterOptions: (state, action) => {
      const {data} = action.payload
      state.cartFilterOptions = data
    },
    setNestedCartsDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setCartsLoading: (state, action) => {
      const {loading} = action.payload
      state.cartsLoading = loading
    },
    setNestedCarts: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const isExist = state.cartNestedList.findIndex((e: any) => e.id === id && e.level === level)
      if (isExist === -1) {
        const updatedColorNestedList = [...state.cartNestedList]
        updatedColorNestedList.push(newItem)
        state.cartNestedList = updatedColorNestedList
      }
    },
  },
})

export const {
  setCartList,
  setShowManageModal,
  setSelectedCart,
  setShowFilterDrawer,
  setCartFilterOptions,
  setCartsLoading,
  setNestedCarts,
  setNestedCartsDepth,
} = cartSlice.actions

export default cartSlice.reducer

export const selectCartsLoading = (state: ICartObject) => state.carts.cartsLoading
export const selectCardList = (state: ICartObject) => state.carts.cartList
export const selectShowFilterDrawer = (state: ICartObject) => state.carts.showFilterDrawer
export const selectCardFilterOptions = (state: ICartObject) => state.carts.cartFilterOptions
export const selectCartNestedDepth = (state: ICartObject) => state.carts.depth
export const selectCartNestedList = (state: ICartObject) => state.carts.cartNestedList
