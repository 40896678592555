import {createSlice} from '@reduxjs/toolkit'
import {ITagObject, initialState, initialTag} from './_models'

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagsList: (state, action) => {
      const {data} = action.payload
      state.tagsList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.tag = initialTag
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedTag: (state, action) => {
      const {data} = action.payload
      state.tag = data
    },
    setTagsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.tagsFilterOptions = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
  },
})

export const {
  setTagsList,
  setShowManageModal,
  setSelectedTag,
  setShowDeleteModal,
  setTagsFilterOptions,
  setShowFilterDrawer,
} = tagsSlice.actions

export default tagsSlice.reducer

export const selectShowManageModal = (state: ITagObject) => state.tags.showManageModal
export const selectTag = (state: ITagObject) => state.tags.tag
export const selectTagsList = (state: ITagObject) => state.tags.tagsList
export const selectShowDeleteModal = (state: ITagObject) => state.tags.showDeleteModal
export const selectTagsFilterOptions = (state: ITagObject) => state.tags.tagsFilterOptions
export const selectShowFilterDrawer = (state: ITagObject) => state.tags.showFilterDrawer
