import {createSlice} from '@reduxjs/toolkit'
import {IProductsObject, initialProduct, initialState, initialUpdateStock} from './_models'

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsList: (state, action) => {
      const {data} = action.payload
      state.productsList = data
    },
    setProductsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.productsFilterOptions = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.product = initialProduct
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowSortProductsModal: (state, action) => {
      const {show} = action.payload
      state.showSortProductsModal = show
    },
    setShowUpdateSellPriceModal: (state, action) => {
      const {show} = action.payload
      state.showUpdateSellPriceModal = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setShowDeleteStockModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteStockModal = show
    },
    setShowStockModal: (state, action) => {
      const {show, variationCode} = action.payload
      state.showStockModal = show
      state.variationCode = variationCode
      if (!show) {
        state.stock = initialUpdateStock
      }
    },
    setSelectedProduct: (state, action) => {
      const {data} = action.payload
      state.product = data
    },
    setSelectedStock: (state, action) => {
      const {data} = action.payload
      state.stock = data
    },
    setDataForCreate: (state, action) => {
      const {data} = action.payload
      state.dataForCreate = data
    },
    setNestedProductsDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setProductsLoading: (state, action) => {
      const {loading} = action.payload
      state.productsLoading = loading
    },
    setNestedProducts: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const isExist = state.productNestedList.findIndex(
        (e: any) => e.id === id && e.level === level
      )
      if (isExist === -1) {
        const updatedProductNestedList = [...state.productNestedList]
        updatedProductNestedList.push(newItem)
        state.productNestedList = updatedProductNestedList
      }
    },
  },
})

export default productsSlice.reducer

export const {
  setProductsList,
  setProductsFilterOptions,
  setShowManageModal,
  setShowFilterDrawer,
  setShowDeleteModal,
  setShowDeleteStockModal,
  setSelectedProduct,
  setDataForCreate,
  setNestedProducts,
  setNestedProductsDepth,
  setProductsLoading,
  setShowStockModal,
  setShowSortProductsModal,
  setShowUpdateSellPriceModal,
  setSelectedStock,
} = productsSlice.actions

export const selectProductsList = (state: IProductsObject) => state.products.productsList
export const selectProductsFilterOptions = (state: IProductsObject) =>
  state.products.productsFilterOptions
export const selectShowManageModal = (state: IProductsObject) => state.products.showManageModal
export const selectShowSortProductsModal = (state: IProductsObject) =>
  state.products.showSortProductsModal
export const selectShowUpdateSellPriceModal = (state: IProductsObject) =>
  state.products.showUpdateSellPriceModal
export const selectShowFilterDrawer = (state: IProductsObject) => state.products.showFilterDrawer
export const selectShowDeleteModal = (state: IProductsObject) => state.products.showDeleteModal
export const selectShowDeleteStockModal = (state: IProductsObject) =>
  state.products.showDeleteStockModal
export const selectProduct = (state: IProductsObject) => state.products.product
export const selectProductsLoading = (state: IProductsObject) => state.products.productsLoading
export const selectProductNestedList = (state: IProductsObject) => state.products.productNestedList
export const selectProductNestedDepth = (state: IProductsObject) => state.products.depth
export const selectdataForCreateProduct = (state: IProductsObject) => state.products.dataForCreate
export const selectShowStockModal = (state: IProductsObject) => state.products.showStockModal
export const selectVariationCode = (state: IProductsObject) => state.products.variationCode
export const selectStock = (state: IProductsObject) => state.products.stock
