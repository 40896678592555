import {logout} from 'features/auth/authSlice'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {RootState} from 'app/store'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, {getState}) => {
    // headers.set('Content-Type', 'multipart/form-data')
    headers.set('Content-Type', 'application/json')
    headers.set('Access-Control-Allow-Origin', '*')
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    api.dispatch(logout({}))
  }
  return result
}

export const apiSlice: any = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
})
