import {createSlice} from '@reduxjs/toolkit'
import {ICouponObject, initialCoupon, initialState} from './_models'

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setCouponsList: (state, action) => {
      const {data} = action.payload
      state.couponsList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.coupon = initialCoupon
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedCoupon: (state, action) => {
      const {data} = action.payload
      state.coupon = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setCouponFilterOptions: (state, action) => {
      const {data} = action.payload
      state.couponsFilterOptions = data
    },
  },
})

export const {
  setCouponsList,
  setShowManageModal,
  setSelectedCoupon,
  setShowDeleteModal,
  setCouponFilterOptions,
  setShowFilterDrawer,
} = couponsSlice.actions

export default couponsSlice.reducer

export const selectShowManageModal = (state: ICouponObject) => state.coupons.showManageModal
export const selectCoupon = (state: ICouponObject) => state.coupons.coupon
export const selectCouponsList = (state: ICouponObject) => state.coupons.couponsList
export const selectShowDeleteModal = (state: ICouponObject) => state.coupons.showDeleteModal
export const selectCouponsFilterOptions = (state: ICouponObject) =>
  state.coupons.couponsFilterOptions
export const selectShowFilterDrawer = (state: ICouponObject) => state.coupons.showFilterDrawer
