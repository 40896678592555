import {FC} from 'react'
import {TLanguageTabModal} from './_models'

type Props = {
  onLangChange: (id: TLanguageTabModal) => void
}

const LanguageTabModal: FC<Props> = ({onLangChange}) => {
  return (
    <ul className='d-flex justify-content-end nav nav-tabs nav-line-tabs nav-line-tabs-2x nav-stretch'>
      <li className='nav-item'>
        <a
          className='nav-link active'
          data-bs-toggle='tab'
          href='#tr'
          onClick={() => onLangChange('tr')}
        >
          Turkish
        </a>
      </li>
      <li className='nav-item'>
        <a className='nav-link' data-bs-toggle='tab' href='#ar' onClick={() => onLangChange('ar')}>
          Arabic
        </a>
      </li>
      <li className='nav-item'>
        <a className='nav-link' data-bs-toggle='tab' href='#en' onClick={() => onLangChange('en')}>
          English
        </a>
      </li>
    </ul>
  )
}

export default LanguageTabModal
