import React from 'react'

type Props = {
  title: string
  description: string
  borderColor: string
}

const StatisticsWidget5: React.FC<Props> = ({title, description, borderColor}) => {
  return (
    <div
      className={`bg-white 
      min-w-250px h-125px p-5 d-flex flex-column gap-3 
      text-center align-items-center justify-content-center border rounded
      border-top-0 border-end-0 border-bottom-0 border-5 border-${borderColor}
      `}
    >
      <div className={`fw-bold fs-1`}>{title}</div>
      <div className={`fw-bold fs-6`}>{description}</div>
    </div>
  )
}

export {StatisticsWidget5}
