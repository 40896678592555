import React from 'react'

const Shape = ({data, onClick}: {data: any; onClick: any}) => {
  return (
    <div
      className='d-flex flex-column my-3 text-center align-items-center justify-content-center cursor-pointer'
      onClick={onClick}
    >
      <img alt='Media' src={data.icon} />
      <span className='fw-bolder fs-7 text-uppercase mt-3 text-gray-900'>{data.name}</span>
    </div>
  )
}

export default Shape
