type TLanguages = 'tr' | 'en' | 'ar'

export type TPage = {
  id?: string | number
  brand_id: any
  category_id: string
  order: number | string
  vat: number | string
  product_id: string | string[]
  mp_code: string
  gtip_code: string
  desi: string
  stock_code: string
  link: string
  is_active: boolean
  contents: Array<{
    language: string
    description: string
    name: string
    slug: string
    body: string
    seo_headline: string
    seo_tags: string
  }>
  sub_products: Array<any>
  categories: Array<string | number>
  colors: any[]
  variations: any[]
}

type TContents =
  | any
  | Array<{
      language: TLanguages
      name: string
      description?: string
      slug: string
      body: string
      seo_headline: string
      seo_tags: string
    }>

type TColors = Array<{
  color_name: string
  color_code: string
  product_color_id: string
  color_id: string
  stock_code: string
  contents: TContents
  media: any
  variations: Array<any>
}>

export type TStoryWidget = {
  title: string
  link: string
  media: string
  id: string
}

export type TTextWidget = {
  body: string
  id: string
}

export type TBannerWidget = {
  width: string
  height: string
  link: string
  media: string
  id: string
}

export type TBoxWidget = {
  title: string
  width: string
  height: string
  link: string
  media: string
  id: string
}

export const initialBoxWidget: TBoxWidget = {
  width: '12',
  height: '',
  link: '',
  media: '',
  id: '',
  title: '',
}

export const initialTextWidget: TTextWidget = {
  body: '',
  id: '',
}

export const initialBannerWidget: TBannerWidget = {
  width: '12',
  height: '',
  link: '',
  media: '',
  id: '',
}

export const initialStoryWidget: TStoryWidget = {
  title: '',
  link: '',
  media: '',
  id: '',
}

export const initialContents: TContents = [
  {
    language: 'tr',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
  {
    language: 'ar',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
  {
    language: 'en',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
]

type TSettingsContents = Array<{
  language: TLanguages
  title: string
  keywords: string
  description: string
  slug: string
}>

export const initialSettingsContents: TSettingsContents = [
  {
    language: 'tr',
    title: '',
    keywords: '',
    description: '',
    slug: '',
  },
  {
    language: 'ar',
    title: '',
    keywords: '',
    description: '',
    slug: '',
  },
  {
    language: 'en',
    title: '',
    keywords: '',
    description: '',
    slug: '',
  },
]

export type TSettings = {
  id?: string
  is_active: boolean
  name: string
  contents: TSettingsContents
}

export const initialSettings: TSettings = {
  is_active: true,
  name: '',
  contents: initialSettingsContents,
}

export type TUpdatePage = {
  id: string
  brand_id: any
  category_id: string
  mp_code: string
  gtip_code: string
  desi: string
  is_active: boolean
  categories: Array<string | number>
  contents: TContents
  colors: TColors
}

export const initialUpdatePage: TUpdatePage = {
  id: '',
  brand_id: '',
  category_id: '',
  mp_code: '',
  gtip_code: '',
  desi: '',
  is_active: true,
  categories: [],
  contents: initialContents,
  colors: [],
}

export const initialPage: TPage = {
  order: 0,
  vat: 8,
  sub_products: [],
  brand_id: '',
  product_id: '',
  category_id: '',
  mp_code: '',
  gtip_code: '',
  desi: '',
  is_active: true,
  categories: [],
  colors: [],
  variations: [],
  link: '',
  stock_code: '',
  contents: initialContents,
}

export interface IPages {
  pagesFilterOptions: Array<any>
  pagesList: Array<TPage>
  showFilterDrawer: boolean
  showManageModal: boolean
  showDeleteModal: boolean
  page: any
  pageNestedList: any[]
  dataForCreate: TUpdatePage
  settings: TSettings
  selectedBoxes: any[]
  selectedBanners: any[]
  selectedStories: any[]
  selectedText: any[]
  selectedStoryForEdit: TStoryWidget
  selectedBannerForEdit: TBannerWidget
  selectedBoxForEdit: TBoxWidget
  selectedTextForEdit: TTextWidget
  showMediaModal: boolean
  showStorySettings: boolean
  showBannerSettings: boolean
  showBoxSettings: boolean
  showTextSettings: boolean
  showWidgetsModal: boolean
  widgetType: string
}

export interface IPagesObject {
  pages: IPages
}

export const initialState: IPages = {
  pagesFilterOptions: [],
  page: initialPage,
  showManageModal: false,
  showWidgetsModal: false,
  showFilterDrawer: false,
  showDeleteModal: false,
  pagesList: [],
  pageNestedList: [],
  dataForCreate: initialUpdatePage,
  settings: initialSettings,
  showMediaModal: false,
  showStorySettings: false,
  selectedBannerForEdit: initialBannerWidget,
  selectedBoxForEdit: initialBoxWidget,
  selectedStoryForEdit: initialStoryWidget,
  showBannerSettings: false,
  showBoxSettings: false,
  widgetType: '',
  selectedBoxes: [],
  selectedBanners: [],
  selectedStories: [],
  selectedText: [],
  selectedTextForEdit: initialTextWidget,
  showTextSettings: false,
}
