import {createSlice} from '@reduxjs/toolkit'
import {IPromotionsObject, initialPromotion, initialState} from './_models'

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    setPromotionsList: (state, action) => {
      const {data} = action.payload
      state.promotionsList = data
    },
    setPromotionsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.promotionsFilterOptions = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.promotion = initialPromotion
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedPromotion: (state, action) => {
      const {data} = action.payload
      state.promotion = data
    },
    setNestedPromotionsDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setPromotionsLoading: (state, action) => {
      const {loading} = action.payload
      state.promotionsLoading = loading
    },
    setNestedPromotions: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const updatedPromotionNestedList = [...state.promotionNestedList]
      updatedPromotionNestedList.push(newItem)
      state.promotionNestedList = updatedPromotionNestedList
    },
  },
})

export const {
  setShowDeleteModal,
  setPromotionsList,
  setShowManageModal,
  setSelectedPromotion,
  setShowFilterDrawer,
  setPromotionsFilterOptions,
  setNestedPromotions,
  setNestedPromotionsDepth,
  setPromotionsLoading,
} = promotionsSlice.actions

export default promotionsSlice.reducer

export const selectShowManageModal = (state: IPromotionsObject) => state.promotions.showManageModal
export const selectPromotionsList = (state: IPromotionsObject) => state.promotions.promotionsList
export const selectPromotion = (state: IPromotionsObject) => state.promotions.promotion
export const selectPromotionsFilterOptions = (state: IPromotionsObject) =>
  state.promotions.promotionsFilterOptions
export const selectShowFilterDrawer = (state: IPromotionsObject) =>
  state.promotions.showFilterDrawer
export const selectShowDeleteModal = (state: IPromotionsObject) => state.promotions.showDeleteModal
export const selectPromotionsLoading = (state: IPromotionsObject) =>
  state.promotions.promotionsLoading
export const selectPromotionNestedList = (state: IPromotionsObject) =>
  state.promotions.promotionNestedList
export const selectPromotionNestedDepth = (state: IPromotionsObject) => state.promotions.depth
