export type TCategory = {
  id?: string | number
  parent_id: string
  order: number | string
  vat: number | string
  is_active: boolean
  is_show_in_menu: boolean
  contents: Array<{
    language: string
    name: string
    slug: string
    body: string
    seo_headline: string
    seo_tags: string
  }>
  sub_categories: Array<any>
  variations: Array<any>
}

export const initialCategory: TCategory = {
  parent_id: '',
  order: 0,
  vat: 8,
  is_active: true,
  is_show_in_menu: true,
  sub_categories: [],
  contents: [
    {
      language: 'tr',
      name: '',
      slug: '',
      body: '',
      seo_headline: '',
      seo_tags: '',
    },
    {
      language: 'ar',
      name: '',
      slug: '',
      body: '',
      seo_headline: '',
      seo_tags: '',
    },
    {
      language: 'en',
      name: '',
      slug: '',
      body: '',
      seo_headline: '',
      seo_tags: '',
    },
  ],
  variations: [],
}

export interface ICategories {
  categoriesFilterOptions: Array<any>
  categoriesList: Array<TCategory>
  showFilterDrawer: boolean
  showManageModal: boolean
  showDeleteModal: boolean
  category: TCategory
  depth: {
    level: number
    url: string
  }
  categoriesLoading: boolean
  categoryNestedList: any[]
}

export interface ICategoriesObject {
  categories: ICategories
}

export const initialState: ICategories = {
  // Filters
  categoriesFilterOptions: [],
  // Data
  category: initialCategory,
  categoriesList: [],
  // Filter Drawer
  showFilterDrawer: false,
  // Modals
  showDeleteModal: false,
  showManageModal: false,
  // Nested
  categoryNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
  categoriesLoading: false,
}
