import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {RenderIf} from 'helpers'
import SelectionCell from './columns/SelectionCell'
import DataTable from 'components/common/DataTable/DataTable'
import {SelectionHeader} from './columns/SelectionHeader'
import {useGetMediaQuery} from 'app/api/services/mediaApiSlice'
import ProgressIndicator from 'components/common/ProgressIndicator/ProgressIndicator'
import {SERVICE_RESPONSE_STATUS} from 'enum/service.enum'

type Props = {
  handleSubmit: (data: any) => void
  isMulti: boolean
}

const MediaList: FC<Props> = ({isMulti, handleSubmit}) => {
  const intl = useIntl()
  const [page, setPage] = useState<number>(1)
  const {data: fetchedData, isFetching, isLoading} = useGetMediaQuery(page)
  const [selectedImages, setSelectedImages] = useState([])

  const pagination = useMemo(() => {
    if (fetchedData && fetchedData.status === SERVICE_RESPONSE_STATUS.SUCCESS) {
      return fetchedData.result.pagination
    }
    return []
  }, [fetchedData])

  const list = useMemo(() => {
    if (fetchedData && fetchedData.status === SERVICE_RESPONSE_STATUS.SUCCESS) {
      return fetchedData.result.data
    }
    return []
  }, [fetchedData])

  const columns = [
    {
      Header: ({...props}) => <SelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell
          isMulti={isMulti}
          data={props.data[props.row.index]}
          onSelectedImages={(data: any) => setSelectedImages(data)}
        />
      ),
    },
    {
      Header: 'Image',
      id: 'image',
      Cell: ({...props}) => {
        const data = props.data[props.row.index].conversions.thumb
        if (data && data.url !== undefined) {
          return <img alt='Media' src={data.url} className='h-30px' />
        } else return <span>No image to preview!</span>
      },
    },
    {
      Header: 'NAME',
      accessor: 'name',
    },
  ]

  return (
    <>
      <RenderIf isTrue={isFetching || isLoading}>
        <ProgressIndicator />
      </RenderIf>
      <div className='mh-350px overflow-auto'>
        <DataTable
          id='medias'
          columns={columns}
          data={list}
          theadClassName='bg-light'
          onPageChange={(page) => setPage(page)}
          pagination={pagination}
        />
      </div>
      <div className='d-flex justify-content-end'>
        <button
          type='button'
          data-testid='submit-file-upload'
          className='m-5 text-center btn btn-primary'
          onClick={() => {
            handleSubmit(selectedImages)
            setSelectedImages([])
          }}
        >
          {intl.formatMessage({id: 'DONE'})}
        </button>
      </div>
    </>
  )
}

export default MediaList
