import {pageScrollIntoView} from 'assets/ts/_utils'
import clsx from 'clsx'
import FormSubmitButton from 'components/FormSubmitButton/FormSubmitButton'
import InputField from 'components/common/InputField/InputField'
import Notification from 'components/common/Notification/Notification'
import {Formik} from 'formik'
import {RenderIf, getContents} from 'helpers'
import {TFormError} from 'models'
import {initialSettings} from 'pages/pages/core/_models'
import {useCreatePageMutation, useEditPageMutation} from 'pages/pages/core/apiSlice'
import {
  selectSelectedBanners,
  selectSelectedBoxes,
  selectSelectedStories,
  selectSelectedText,
  selectSettings,
} from 'pages/pages/core/slice'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router'
import {toast} from 'react-toastify'

type Props = {
  lang?: string
  setLang?: any
  pageId?: string
  onFormikChangeValues: any
}

function removeEmptyProperties(obj: any) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (typeof obj[prop] === 'object') {
        if (Array.isArray(obj[prop])) {
          obj[prop] = obj[prop].filter(Boolean)
        } else if (Object.keys(obj[prop]).length === 0) {
          delete obj[prop]
        } else {
          removeEmptyProperties(obj[prop])
        }
      }
      if (
        obj[prop] === null ||
        obj[prop] === '' ||
        (Array.isArray(obj[prop]) && obj[prop].length === 0)
      ) {
        delete obj[prop]
      }
    }
  }
}

const Settings = ({lang, setLang, onFormikChangeValues}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const details = useSelector(selectSettings)
  const updatedBanners = useSelector(selectSelectedBanners)
  const updatedStories = useSelector(selectSelectedStories)
  const updatedBoxes = useSelector(selectSelectedBoxes)
  const updatedText = useSelector(selectSelectedText)
  const [createPage] = useCreatePageMutation()
  const [editPage] = useEditPageMutation()
  const [error, setError] = useState<TFormError>({
    title: '',
    desc: '',
    list: [],
  })

  const getDataForEdit = () => {
    return {
      ...details,
      contents: getContents(initialSettings.contents, details.contents) || initialSettings.contents,
    }
  }

  const handleGetItem = (shapes: any, lang: any, frame: any, section: any) => {
    const result = []
    for (let i = 0; i < shapes.length; i++) {
      if (shapes[i].frame === frame && shapes[i].lang === lang && shapes[i].section === section) {
        const item = shapes[i]
        if (item.shape === 'story') {
          result.push({
            media: item.media,
            link: item.link,
            title: item.title,
          })
        } else if (item.shape === 'banner') {
          result.push({
            media: item.media,
            link: item.link,
            height: item.height,
            width: item.width,
          })
        } else if (item.shape === 'boxes') {
          result.push({
            media: item.media,
            link: item.link,
            height: item.height,
            width: item.width,
            title: item.title,
          })
        } else if (item.shape === 'text') {
          result.push({
            body: item.body,
          })
        } else {
        }
      }
    }
    return result
  }

  const handleDataForCreate = (updatedShapes: any, values: any) => {
    const trIdx = values.findIndex((i: any) => i.language === 'tr')
    const enIdx = values.findIndex((i: any) => i.language === 'en')
    const arIdx = values.findIndex((i: any) => i.language === 'ar')

    const trWidgets: any = {
      mobile: [],
      web: [],
    }
    const enWidgets: any = {
      mobile: [],
      web: [],
    }
    const arWidgets: any = {
      mobile: [],
      web: [],
    }

    for (let i = 0; i < updatedShapes.length; i++) {
      if (updatedShapes[i].lang === 'tr') {
        trWidgets[updatedShapes[i].frame][updatedShapes[i].section] = {
          type: updatedShapes[i].shape,
          items: handleGetItem(
            updatedShapes,
            updatedShapes[i].lang,
            updatedShapes[i].frame,
            updatedShapes[i].section
          ),
        }
      }
      if (updatedShapes[i].lang === 'en') {
        enWidgets[updatedShapes[i].frame][updatedShapes[i].section] = {
          type: updatedShapes[i].shape,
          items: handleGetItem(
            updatedShapes,
            updatedShapes[i].lang,
            updatedShapes[i].frame,
            updatedShapes[i].section
          ),
        }
      }
      if (updatedShapes[i].lang === 'ar') {
        arWidgets[updatedShapes[i].frame][updatedShapes[i].section] = {
          type: updatedShapes[i].shape,
          items: handleGetItem(
            updatedShapes,
            updatedShapes[i].lang,
            updatedShapes[i].frame,
            updatedShapes[i].section
          ),
        }
      }
    }

    const contents = {
      tr: {
        title: values[trIdx].title,
        keywords: values[trIdx].keywords,
        description: values[trIdx].description,
        slug: values[trIdx].slug,
        widgets: trWidgets,
      },
      en: {
        title: values[enIdx].title,
        keywords: values[enIdx].keywords,
        description: values[enIdx].description,
        slug: values[enIdx].slug,
        widgets: enWidgets,
      },
      ar: {
        title: values[arIdx].title,
        keywords: values[arIdx].keywords,
        description: values[arIdx].description,
        slug: values[arIdx].slug,
        widgets: arWidgets,
      },
    }

    removeEmptyProperties(contents)
    removeEmptyProperties(contents)
    removeEmptyProperties(contents)

    return contents
  }

  return (
    <div className='w-300px d-flex flex-column shadow-sm p-5 bg-white sticky-top'>
      <div className='mt-3 text-center'>
        <h3 style={{color: '#3F4254', fontSize: '1rem'}}>{intl.formatMessage({id: 'SETTINGS'})}</h3>
      </div>
      <div className='border-top border-gray-300 w-100 my-3' />
      <div className='mt-3 text-start'>
        <h3 style={{color: '#3D3D3D', fontSize: '1rem'}}>
          {intl.formatMessage({id: 'PAGE_SETTINGS'})}
        </h3>
      </div>
      <div className='border-top border-gray-300 w-100 my-3' />
      <Formik
        enableReinitialize={true}
        initialValues={getDataForEdit()}
        onSubmit={async (values: any, actions: any) => {
          const allShapes = [...updatedBanners, ...updatedStories, ...updatedBoxes, ...updatedText]
          const contents = handleDataForCreate(allShapes, values.contents)

          const dataForCreate = {
            is_active: values.is_active,
            name: values.name,
            contents: contents,
          }

          const dataForEdit = {
            is_active: values.is_active,
            name: values.name,
            contents: contents,
            id: values.id,
          }
          actions.setSubmitting(true)

          if (values.id !== undefined) {
            try {
              const res = await editPage(dataForEdit)
              if (!res?.error) {
                toast.success(intl.formatMessage({id: 'EDIT_DONE'}))
                setLang('tr')
                setError({
                  title: '',
                  desc: '',
                  list: [],
                })
                navigate(`/pages/list?page=1`)
              }
              if (res?.error) {
                const {result, message} = res.error?.data
                const errors = Object.entries(result).map(([key, value]: any) => {
                  return {
                    key,
                    value: value[0],
                  }
                })
                setError({
                  title: intl.formatMessage({id: 'FAILED_TO_EDIT'}),
                  desc: message,
                  list: errors,
                })
              }
              pageScrollIntoView()
            } catch (err: any) {
              setError({
                title: intl.formatMessage({id: 'FAILED_TO_EDIT'}),
                desc: err?.response.data.message ?? intl.formatMessage({id: 'SUPPORT_TEAM'}),
                list: [],
              })
              pageScrollIntoView()
            }
          } else {
            try {
              const res = await createPage(dataForCreate)
              if (!res?.error) {
                toast.success(intl.formatMessage({id: 'CREATE_DONE'}))
                setLang('tr')
                setError({
                  title: '',
                  desc: '',
                  list: [],
                })
                navigate(`/pages/list?page=1`)
              }
              if (res?.error) {
                const {result, message} = res.error?.data
                const errors = Object.entries(result).map(([key, value]: any) => {
                  return {
                    key,
                    value: value[0],
                  }
                })
                setError({
                  title: intl.formatMessage({id: 'FAILED_TO_CREATE'}),
                  desc: message,
                  list: errors,
                })
              }
              pageScrollIntoView()
            } catch (err: any) {
              pageScrollIntoView()
              setError({
                title: intl.formatMessage({id: 'FAILED_TO_CREATE'}),
                desc: err?.response.data.message ?? intl.formatMessage({id: 'SUPPORT_TEAM'}),
                list: [],
              })
            }
          }
          actions.setSubmitting(false)
        }}
      >
        {({values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => {
          const contentIdx = values.contents.findIndex((i: any) => i.language === lang)
          onFormikChangeValues(values)
          return (
            <>
              <RenderIf isTrue={error.title !== '' || error.desc !== ''}>
                <Notification
                  headerTitle={error.title}
                  bodyTitle={error.desc}
                  errorsList={error.list}
                />
              </RenderIf>
              <form className='form' onSubmit={handleSubmit}>
                <div className='fv-row mb-6 d-flex justify-content-between'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'STATUS'})}
                  </label>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='is_active'
                      value={String(values.is_active)}
                      onChange={(evt) => setFieldValue('is_active', evt.target.checked)}
                      defaultChecked={Boolean(values.is_active)}
                    />
                    <label
                      className={clsx(
                        'w-50px form-check-label badge',
                        values.is_active ? 'badge-success text-white' : 'badge-light'
                      )}
                    >
                      {values.is_active
                        ? intl.formatMessage({id: 'PUBLISH'})
                        : intl.formatMessage({id: 'DRAFT'})}
                    </label>
                  </div>
                </div>
                <div className='fv-row mb-6'>
                  <InputField
                    label={intl.formatMessage({id: 'PAGE_NAME'})}
                    placeholder={intl.formatMessage({id: 'NAME'})}
                    value={values.name}
                    name={`name`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    className='mb-3 w-100'
                  />
                </div>

                <div className='fv-row mb-6'>
                  <InputField
                    label={intl.formatMessage({id: 'TITLE'})}
                    placeholder={intl.formatMessage({id: 'TITLE'})}
                    value={values.contents[contentIdx].title}
                    name={`contents.[${contentIdx}].title`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    className='mb-3 w-100'
                  />
                </div>

                <div className='fv-row mb-6'>
                  <label className='fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'DESCRIPTION'})}
                  </label>
                  <textarea
                    placeholder={intl.formatMessage({id: 'WRITE_DETAILS'})}
                    className='form-control form-control-solid mb-3'
                    value={values.contents[contentIdx].description}
                    name={`contents.[${contentIdx}].description`}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={3}
                  />
                </div>

                <div className='fv-row mb-6'>
                  <InputField
                    label={intl.formatMessage({id: 'KEYWORDS'})}
                    placeholder={intl.formatMessage({id: 'KEYWORDS'})}
                    value={values.contents[contentIdx].keywords}
                    name={`contents.[${contentIdx}].keywords`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    className='mb-3 w-100'
                  />
                </div>

                <div className='fv-row mb-6'>
                  <InputField
                    label={intl.formatMessage({id: 'SLUG'})}
                    placeholder={intl.formatMessage({id: 'SLUG'})}
                    value={values.contents[contentIdx].slug}
                    name={`contents.[${contentIdx}].slug`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    className='mb-3 w-100'
                  />
                </div>
                <FormSubmitButton isSubmitting={isSubmitting} details={details} />
              </form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default Settings
