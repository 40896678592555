import {FC, useState, useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {onClear, onSelect, selectedMedias} from '../core/slice'
import {useSelector} from 'react-redux'

type Props = {
  data: any
  isMulti: boolean
  onSelectedImages: (data: any) => void
}

const SelectionCell: FC<Props> = ({data, isMulti, onSelectedImages}) => {
  const dispatch = useDispatch()
  const [id, setId] = useState('')
  const [conversions, setConversions] = useState<any>()
  const selectedItems = useSelector(selectedMedias)

  useEffect(() => {
    setId(data.id)
    setConversions({
      large: data.conversions?.large?.url,
      medium: data.conversions?.medium?.url,
      original: data.conversions?.original?.url,
      thumb: data.conversions?.thumb?.url,
    })
  }, [data])

  const isSelected = useMemo(() => {
    const isExist = selectedItems.findIndex((e: any) => e.id === id)
    if (isExist > -1) return true
    return false
  }, [id, selectedItems])

  const handleSingleChoice = () => {
    if (selectedItems.length === 1) {
      dispatch(onClear())
      dispatch(onSelect({id, conversions}))
      onSelectedImages({id, conversions})
    } else if (selectedItems.length === 0) {
      dispatch(onSelect({id, conversions}))
      onSelectedImages({id, conversions})
    } else return
  }

  return (
    <div className='form-check form-check-custom form-check-solid ps-8'>
      <input
        className='form-check-input cursor-pointer'
        type='checkbox'
        value={data}
        data-kt-check={isSelected}
        checked={isSelected}
        onClick={() => {
          if (isMulti) {
            dispatch(onSelect({id, conversions}))
            onSelectedImages({id, conversions})
          } else {
            handleSingleChoice()
          }
        }}
      />
    </div>
  )
}

export default SelectionCell
