import {getCSSVariableValue} from 'assets/ts/_utils'
import {WithChildren} from 'helpers'
import {MasterLayout} from 'layout/MasterLayout'
import {DashboardWrapper} from 'pages/dashboard/DashboardWrapper'
import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

const PrivateRoutes = () => {
  const ProductsPage = lazy(() => import('pages/products/ProductsPage'))
  const ProductColorsPage = lazy(() => import('pages/product-colors/ProductColorsPage'))
  const PagesPage = lazy(() => import('pages/pages/PagesPage'))
  const CartListPage = lazy(() => import('pages/cart-List/CartListPage'))

  // Definitions Start
  const CategoriesPage = lazy(() => import('pages/categories/CategoriesPage'))
  const ColorsPage = lazy(() => import('pages/colors/ColorsPage'))
  const OrderDetails = lazy(() => import('pages/order-details/OrderDetailPage'))
  const OrderListPage = lazy(() => import('pages/order-list/OrderListPage'))
  const DailySellPage = lazy(() => import('pages/daily-sell/DailySellPage'))
  const UsersPage = lazy(() => import('pages/users/UsersPage'))
  const VariatonPage = lazy(() => import('pages/variation/VariationPage'))
  const BadgesPage = lazy(() => import('pages/badges/BadgesPage'))
  const CurrenciesPage = lazy(() => import('pages/currencies/CurrenciesPage'))
  const CouponPage = lazy(() => import('pages/coupons/CouponsPage'))
  const MenusPage = lazy(() => import('pages/menus/MenusPage'))
  const TagsPage = lazy(() => import('pages/tags/TagsPage'))
  const PromotionsPage = lazy(() => import('pages/promotions/PromotionsPage'))
  const WholesaleProductsPage = lazy(() => import('pages/wholesale-products/WholesaleProductsPage'))
  const SaleChannelsPage = lazy(() => import('pages/sale-channels/SaleChannelsPage'))
  // Definitions End

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='products/product-colors/*'
          element={
            <SuspensedView>
              <ProductColorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='products/wholesale/*'
          element={
            <SuspensedView>
              <WholesaleProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotions/*'
          element={
            <SuspensedView>
              <PromotionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/*'
          element={
            <SuspensedView>
              <PagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='definitions/categories/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='definitions/variation-list/*'
          element={
            <SuspensedView>
              <VariatonPage />
            </SuspensedView>
          }
        />
        <Route
          path='orders/order-list/*'
          element={
            <SuspensedView>
              <OrderListPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/daily-sell/*'
          element={
            <SuspensedView>
              <DailySellPage />
            </SuspensedView>
          }
        />
        <Route
          path='definitions/colors/*'
          element={
            <SuspensedView>
              <ColorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='definitions/order-details/:id'
          element={
            <SuspensedView>
              <OrderDetails />
            </SuspensedView>
          }
        />
        <Route
          path='definitions/badges/*'
          element={
            <SuspensedView>
              <BadgesPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/menus/*'
          element={
            <SuspensedView>
              <MenusPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/currencies/*'
          element={
            <SuspensedView>
              <CurrenciesPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/sales-channels/*'
          element={
            <SuspensedView>
              <SaleChannelsPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/coupons/*'
          element={
            <SuspensedView>
              <CouponPage />
            </SuspensedView>
          }
        />
        <Route
          path='definitions/tags/*'
          element={
            <SuspensedView>
              <TagsPage />
            </SuspensedView>
          }
        />
        <Route
          path='carts/cart-list/*'
          element={
            <SuspensedView>
              <CartListPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
