import {useState, useEffect} from 'react'
import Header from './header'
import Widgets from './widgets'
import {RenderIf, getContents} from 'helpers'
import Settings from './settings'
import {TLanguageTabModal} from 'components/common/LanguageTabModal/_models'
import StorySettings from './settings/StorySettings'
import {useSelector} from 'react-redux'
import {
  selectShowBannerSettings,
  selectShowBoxSettings,
  selectShowStorySettings,
  selectShowTextSettings,
  selectShowWidgetsModal,
  setSelectedShape,
  setShowWidgetsModal,
  updateSelectedSettings,
} from '../core/slice'
import BoxSettings from './settings/BoxSettings'
import BannerSettings from './settings/BannerSettings'
import Frame from './frame'
import {useParams} from 'react-router-dom'
import {useGetPageDetailsQuery} from '../core/apiSlice'
import {TSettings, initialSettingsContents} from '../core/_models'
import {useDispatch} from 'react-redux'
import EditModal from 'components/common/EditModal/EditModal'
import TextSettings from './settings/TextSettings'

const CreatePage = () => {
  const isStorySettings = useSelector(selectShowStorySettings)
  const isTextSettings = useSelector(selectShowTextSettings)
  const isBannerSettings = useSelector(selectShowBannerSettings)
  const isBoxSettings = useSelector(selectShowBoxSettings)
  const [lang, setLang] = useState<TLanguageTabModal>('tr')
  const [frame, setFrame] = useState('mobile')
  const showWidgetsModal = useSelector(selectShowWidgetsModal)
  const [section, setSection] = useState({
    id: 0,
    lang: 'tr',
    frame: 'mobile',
  })
  const dispatch = useDispatch()
  const {id: pageId} = useParams()

  const [verticalSection, setVerticalSection] = useState([
    {
      frame: 'mobile',
      lang: 'en',
      items: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
    },
    {
      frame: 'mobile',
      lang: 'tr',
      items: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
    },
    {
      frame: 'mobile',
      lang: 'ar',
      items: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
    },
    {
      frame: 'web',
      lang: 'en',
      items: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
    },
    {
      frame: 'web',
      lang: 'tr',
      items: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
    },
    {
      frame: 'web',
      lang: 'ar',
      items: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
    },
  ])

  const {data: fetchedData} = useGetPageDetailsQuery(pageId)

  const getDataFromDetails = (data: any) => {
    const entries = Object.entries(data.contents)
    const contents = entries.map(([lang, contents]: [string, any]) => {
      return {
        language: lang,
        title: contents.title,
        keywords: contents.keywords,
        description: contents.description,
        slug: contents.slug,
      }
    })
    const filteredContents = getContents(initialSettingsContents, contents)
    const values: TSettings = {
      id: data.id,
      is_active: data.is_active,
      name: data.name,
      contents: filteredContents,
    }
    dispatch(updateSelectedSettings({data: values}))

    const handleGetItem = (items: any, lang: string, frame: string) => {
      for (let i = 0; i < items.length; i++) {
        const section = i
        const type = items[i].type

        items[i]?.items?.forEach((item: any) => {
          dispatch(
            setSelectedShape({
              data: type,
              frame,
              lang,
              section,
              title: item.title,
              media: item.media,
              link: item.link,
              width: item.width,
              height: item.height,
              body: item.body,
            })
          )
        })
      }
    }

    entries.forEach(([lang, contents]: [string, any]) => {
      const widgets = contents.widgets
      if (widgets?.mobile?.length > 0) {
        handleGetItem(widgets.mobile, lang, 'mobile')
      }
      if (widgets?.web?.length > 0) {
        handleGetItem(widgets.web, lang, 'web')
      }
    })
  }

  useEffect(() => {
    if (fetchedData) {
      getDataFromDetails(fetchedData.result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData])

  return (
    <>
      <div className='flex-column flex-row-fluid'>
        <Header onFrameChange={setFrame} onLangChange={setLang} />
        <div className='d-flex justify-content-between'>
          <div className='w-300px' />

          <div className='ms-5 mt-5'>
            <Frame
              lang={lang}
              onSectionChange={(id: number, frame: string, lang: string) => {
                setSection({
                  id,
                  lang,
                  frame,
                })
              }}
              verticalSection={
                verticalSection.filter((i) => i.frame === frame && i.lang === lang)[0].items
              }
              setVerticalSection={(data: any, frame: string, lang: string) => {
                const newArray = verticalSection.map((i) => {
                  if (i.frame === frame && i.lang === lang) {
                    return {
                      ...i,
                      items: data,
                    }
                  } else {
                    return i
                  }
                })
                setVerticalSection(newArray)
              }}
              frame={frame}
            />
          </div>

          <div>
            <RenderIf isTrue={isTextSettings}>
              <TextSettings />
            </RenderIf>
            <RenderIf isTrue={isStorySettings}>
              <StorySettings />
            </RenderIf>
            <RenderIf isTrue={isBannerSettings}>
              <BannerSettings />
            </RenderIf>
            <RenderIf isTrue={isBoxSettings}>
              <BoxSettings />
            </RenderIf>
            <div
              className='h-100'
              style={{
                display: `${
                  !isStorySettings && !isBoxSettings && !isBannerSettings && !isTextSettings
                    ? 'inline'
                    : 'none'
                } `,
              }}
            >
              <Settings
                lang={lang}
                setLang={setLang}
                pageId={pageId}
                onFormikChangeValues={(values: any) => {
                  const isShowSettings =
                    !isStorySettings && !isBoxSettings && !isBannerSettings && !isTextSettings
                  if (!isShowSettings) {
                    dispatch(updateSelectedSettings({data: values}))
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <EditModal
        id='widgets'
        show={showWidgetsModal}
        handleClose={() => dispatch(setShowWidgetsModal({show: false}))}
        headerTitle='Widgets'
        isMultiLanguage={false}
        modalWidth='mw-400px'
      >
        <Widgets section={section} />
      </EditModal>
    </>
  )
}

export default CreatePage
