type TLanguages = 'tr' | 'en' | 'ar'

export enum menuType {
  staticLink = 'static',
  category = 'category',
  page = 'page',
}

export type TMenu = {
  id?: string | number
  location: string
  is_active: boolean
  contents: Array<{
    language: string
    name: string
  }>
  items: Array<any>
}

export type TMenuContents = Array<{
  language: TLanguages
  name: string
  slug: string
}>

export const initialMenuContents: TMenuContents = [
  {
    language: 'tr',
    name: '',
    slug: '',
  },
  {
    language: 'ar',
    name: '',
    slug: '',
  },
  {
    language: 'en',
    name: '',
    slug: '',
  },
]

export const initialMenu: TMenu = {
  location: '',
  is_active: true,
  contents: [
    {
      language: 'tr',
      name: '',
    },
    {
      language: 'ar',
      name: '',
    },
    {
      language: 'en',
      name: '',
    },
  ],
  items: [],
}

export interface IMenus {
  menusFilterOptions: Array<any>
  menusList: Array<TMenu>
  showFilterDrawer: boolean
  showManageModal: boolean
  showDeleteModal: boolean
  showMediaModal: boolean
  menuSettings: Array<any>
  menu: TMenu
}

export interface IMenusObject {
  menus: IMenus
}

export const initialState: IMenus = {
  menu: initialMenu,
  menusList: [],
  menuSettings: [],
  showDeleteModal: false,
  showManageModal: false,
  showMediaModal: false,
  menusFilterOptions: [],
  showFilterDrawer: false,
}
