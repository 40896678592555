import {createSlice} from '@reduxjs/toolkit'
import {ISaleChannelObject, initialSaleChannel, initialState} from './_models'

const saleChannelsSlice = createSlice({
  name: 'saleChannels',
  initialState,
  reducers: {
    setSaleChannelsList: (state, action) => {
      const {data} = action.payload
      state.saleChannelsList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.saleChannel = initialSaleChannel
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedSaleChannel: (state, action) => {
      const {data} = action.payload
      state.saleChannel = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setSaleChannelsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.saleChannelsFilterOptions = data
    },
  },
})

export const {
  setSaleChannelsList,
  setShowManageModal,
  setSelectedSaleChannel,
  setShowDeleteModal,
  setSaleChannelsFilterOptions,
  setShowFilterDrawer,
} = saleChannelsSlice.actions

export default saleChannelsSlice.reducer

export const selectShowManageModal = (state: ISaleChannelObject) =>
  state.saleChannels.showManageModal
export const selectSaleChannel = (state: ISaleChannelObject) => state.saleChannels.saleChannel
export const selectSaleChannelsList = (state: ISaleChannelObject) =>
  state.saleChannels.saleChannelsList
export const selectShowDeleteModal = (state: ISaleChannelObject) =>
  state.saleChannels.showDeleteModal
export const selectSaleChannelsFilterOptions = (state: ISaleChannelObject) =>
  state.saleChannels.saleChannelsFilterOptions
export const selectShowFilterDrawer = (state: ISaleChannelObject) =>
  state.saleChannels.showFilterDrawer
