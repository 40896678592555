import ModalLanguageTab from 'components/common/LanguageTabModal'
import {TLanguageTabModal} from 'components/common/LanguageTabModal/_models'

type Props = {
  handleClose: () => void
  onLangChange: (id: TLanguageTabModal) => void
  adminName: string
  title: string
  isMultiLanguage: boolean
}

const Header = ({handleClose, onLangChange, adminName, title, isMultiLanguage}: Props) => {
  return (
    <div className='modal-header d-flex flex-column border-bottom-0' data-testid='modal-header-title'>
      <div className='d-flex w-100 justify-content-between align-items-center'>
        <h2>{title}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <i className='fa-solid fa-xmark fs-2' />
        </div>
      </div>
      <div className='w-100'>
        <div className='d-flex align-items-center'>
          <span className='bullet bullet-dot bg-secondary h-15px w-15px me-3' />
          {adminName}
        </div>
        {isMultiLanguage && <ModalLanguageTab onLangChange={onLangChange} />}
      </div>
    </div>
  )
}

export default Header
