export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const pretifyName = (name: string) => {
  return name
    .replace(/[-_.]/g, ' ')
    .replace(/ +/g, ' ')
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const intlDateFormatter = (intl: any, date: string) => {
  const formattedUTCDate = new Date(date)

  // Manually format the date with hyphens
  const createdYear = intl.formatDate(formattedUTCDate, {
    year: 'numeric',
  })
  const createdMonth = intl.formatDate(formattedUTCDate, {
    month: '2-digit',
  })
  const createdDay = intl.formatDate(formattedUTCDate, {
    day: '2-digit',
  })

  const formattedDate = `${createdYear}-${createdMonth}-${createdDay}`

  const formattedTime = intl.formatTime(formattedUTCDate, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return {
    formattedDate,
    formattedTime,
  }
}
