import {setSelectedShape, setShowWidgetsModal, setWidgetType} from 'pages/pages/core/slice'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'

import styled from 'styled-components'

const AddWidgetButton = styled.button`
  color: #b5b5c3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const AddWidgetSideButton = styled.label`
  position: absolute;
  top: 25px;
  left: -84px;
  text-align: center;
`
const Section = ({
  children,
  style,
  className,
  index,
  frame,
  lang,
}: {
  children: any
  style: any
  className: any
  index: any
  frame: string
  lang: string
}) => {
  const dispatch = useDispatch()
  const [isEmpty, setIsEmpty] = useState(false)
  const [widget, setWidget] = useState('')
  const [isNotBanner, setIsNotBanner] = useState(false)
  const [isText, setIsText] = useState(false)

  const getChildrenDetails = (children: any) => {
    const [storyWidgets, bannerWidgets, boxWidgets, textWidgets] = children
    const filteredStoryWidgets = storyWidgets.filter((item: any) => item !== null)
    const filteredBannerWidgets = bannerWidgets.filter((item: any) => item !== null)
    const filteredBoxWidgets = boxWidgets.filter((item: any) => item !== null)
    const filteredTextWidgets = textWidgets.filter((item: any) => item !== null)

    if (
      filteredStoryWidgets.length === 0 &&
      filteredBannerWidgets.length === 0 &&
      filteredBoxWidgets.length === 0 &&
      filteredTextWidgets.length === 0
    ) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
      if (filteredStoryWidgets[0]?.key?.includes('story')) {
        setWidget('story')
        setIsText(false)
        setIsNotBanner(true)
      } else if (filteredBannerWidgets[0]?.key?.includes('banner')) {
        setWidget('banner')
        setIsText(false)
        setIsNotBanner(false)
      } else if (filteredBoxWidgets[0]?.key?.includes('boxes')) {
        setWidget('boxes')
        setIsText(false)
        setIsNotBanner(true)
      } else if (filteredTextWidgets[0]?.key.includes('text')) {
        setWidget('text')
        setIsText(true)
        setIsNotBanner(false)
      } else {
        setWidget('')
      }
    }
  }

  useEffect(() => {
    getChildrenDetails(children)
  }, [children])
  const intl = useIntl()

  return (
    <>
      {!isEmpty && !isText && (
        <AddWidgetSideButton className='badge badge-secondary'>
          <span
            className='cursor-pointer text-white w-60px'
            onClick={() => {
              dispatch(setSelectedShape({data: widget, frame, lang, section: index}))
            }}
          >
            {`Add ${widget.charAt(0).toUpperCase() + widget.slice(1)}`}
          </span>
        </AddWidgetSideButton>
      )}
      <div style={style} className={`${className} ${isNotBanner && 'flex-nowrap'}`}>
        {isEmpty ? (
          <AddWidgetButton
            className='btn btn-sm btn-active-primary'
            onClick={() => {
              dispatch(setShowWidgetsModal({show: true}))
              dispatch(setWidgetType({widget: ''}))
            }}
          >
            {intl.formatMessage({id: 'ADD_WIDGET'})}
          </AddWidgetButton>
        ) : (
          children
        )}
      </div>
    </>
  )
}

export default Section
