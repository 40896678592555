import {DatePicker} from 'antd'
import {useGetStatisticsQuery} from 'app/api/services/statisticsApiSlice'
import {StatisticsWidget5} from 'components/Statistics/StatisticsWidget5'
import ProgressIndicator from 'components/common/ProgressIndicator/ProgressIndicator'
import {SERVICE_RESPONSE_STATUS} from 'enum/service.enum'
import {PageTitle as PageTitleHeader, RenderIf} from 'helpers'
import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'

export const DashboardWrapper: FC = () => <Statistics />

const Statistics: FC = () => {
  const [filters, setFilters] = useState({})
  const {data: fetchedData, isLoading, isFetching} = useGetStatisticsQuery(filters)
  const intl = useIntl()
  const {
    total_sell_with_addons,
    total_orders_count,
    total_product_sell,
    total_buy_price,
    total_quantity,
    total_profit,
    total_tax,
    total_profit_percent,
    total_product_sub_total,
  } = useMemo(() => {
    if (fetchedData && fetchedData.status === SERVICE_RESPONSE_STATUS.SUCCESS) {
      return fetchedData.result
    }
    return []
  }, [fetchedData])

  const updateFilter = (id: string, value: string) =>
    setFilters((oldState) => {
      return {...oldState, [id]: value}
    })

  return (
    <>
      <RenderIf isTrue={isFetching || isLoading}>
        <ProgressIndicator />
      </RenderIf>
      <PageTitleHeader title={intl.formatMessage({id: 'SUMMARY'})} routeTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})} />
      <div className='col-12 col-sm-6 col-md-4 mt-4'>
        <label className='form-label fw-bold'>{intl.formatMessage({id: 'DATE'})}:</label>
        <DatePicker
          rootClassName='h-40px'
          onChange={(_, dateString) => {
            updateFilter('date', dateString)
          }}
          placeholder={intl.formatMessage({id: 'SELECT_DATE'})}
          className='w-100'
        />
      </div>
      <div className='d-flex flex-wrap gap-8 mt-5'>
        <StatisticsWidget5
          borderColor='warning'
          title={total_orders_count ?? 0}
          description={intl.formatMessage({id: 'TOTAL_ORDER_COUNT'})}
        />
        <StatisticsWidget5
          borderColor='info'
          title={total_quantity ?? 0}
          description={intl.formatMessage({id: 'TOTAL_QUANTITY'})}
        />
        <StatisticsWidget5
          borderColor='secondary'
          title={`₺ ${total_buy_price ?? 0}`}
          description={intl.formatMessage({id: 'TOTAL_BUY_PRICE'})}
        />
        <StatisticsWidget5
          borderColor='danger'
          title={`₺ ${total_product_sell ?? 0}`}
          description={intl.formatMessage({id: 'TOTAL_PRODUCT_SELL'})}
        />
        <StatisticsWidget5
          borderColor='success'
          title={`₺ ${total_profit ?? 0}`}
          description={intl.formatMessage({id: 'TOTAL_PROFIT'})}
        />
        <StatisticsWidget5
          borderColor='muted'
          title={`${total_profit_percent ?? 0} %`}
          description={intl.formatMessage({id: 'TOTAL_PROFIT_PERCENT'})}
        />
        <StatisticsWidget5
          borderColor='info'
          title={`₺ ${total_product_sub_total ?? 0}`}
          description={intl.formatMessage({id: 'TOTAL_PRODUCT_SUB_TOTAL'})}
        />
      </div>
    </>
  )
}
