import {createSlice} from '@reduxjs/toolkit'
import {IColorObject, initialState} from './_models'

const colorsSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    setColorsList: (state, action) => {
      const {data} = action.payload
      state.colorsList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.color = initialState
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
      if (!show) {
        state.color = initialState
      }
    },
    setSelectedColor: (state, action) => {
      const {data} = action.payload
      state.color = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setColorsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.colorsFilterOptions = data
    },
    setNestedColorsDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setColorsLoading: (state, action) => {
      const {loading} = action.payload
      state.colorsLoading = loading
    },
    setNestedColors: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const isExist = state.colorNestedList.findIndex((e: any) => e.id === id && e.level === level)
      if (isExist === -1) {
        const updatedColorNestedList = [...state.colorNestedList]
        updatedColorNestedList.push(newItem)
        state.colorNestedList = updatedColorNestedList
      }
    },
  },
})

export const {
  setShowDeleteModal,
  setColorsList,
  setShowManageModal,
  setSelectedColor,
  setShowFilterDrawer,
  setColorsFilterOptions,
  setColorsLoading,
  setNestedColors,
  setNestedColorsDepth,
} = colorsSlice.actions

export default colorsSlice.reducer

export const selectShowManageModal = (state: IColorObject) => state.colors.showManageModal
export const selectColor = (state: IColorObject) => state.colors.color
export const selectColorsList = (state: IColorObject) => state.colors.colorsList
export const selectColorsFilterOptions = (state: IColorObject) => state.colors.colorsFilterOptions
export const selectShowDeleteModal = (state: IColorObject) => state.colors.showDeleteModal
export const selectShowFilterDrawer = (state: IColorObject) => state.colors.showFilterDrawer
export const selectColorsLoading = (state: IColorObject) => state.colors.colorsLoading
export const selectColorNestedList = (state: IColorObject) => state.colors.colorNestedList
export const selectColorNestedDepth = (state: IColorObject) => state.colors.depth
