export const generateRandomId = () => {
  return (Math.random().toString(36) + Date.now().toString(36)).substring(2, 10)
}

export const getCountryName = (locale) => {
  switch (locale) {
    case 'en':
      return 'English'
    case 'ar':
      return 'Arabic'
    case 'tr':
      return 'Turkish'
    default:
      break
  }
}
