import {createSlice} from '@reduxjs/toolkit'
import {IWholesaleProducts, IWholesaleProductsObject, initialWholesaleProduct} from './_models'
import {TTableNestedItem} from 'models'

const initialState: IWholesaleProducts = {
  // Filters
  wholesaleProductsFilterOptions: [],
  // Data
  wholesaleProduct: initialWholesaleProduct,
  wholesaleProductsList: [],
  // Filter Drawer
  showFilterDrawer: false,
  // Modals
  showManageModal: false,
  showDeleteModal: false,
  // Nested
  wholesaleProductNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
  wholesaleProductsLoading: false,
}

const wholesaleProductsSlice = createSlice({
  name: 'wholesaleProducts',
  initialState,
  reducers: {
    setWholesaleProductsList: (state, action) => {
      const {data} = action.payload
      state.wholesaleProductsList = data
    },
    setWholesaleProductsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.wholesaleProductsFilterOptions = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.wholesaleProduct = initialWholesaleProduct
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedProduct: (state, action) => {
      const {data} = action.payload
      state.wholesaleProduct = data
    },
    setNestedWholesaleProductsDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setWholesaleProductsLoading: (state, action) => {
      const {loading} = action.payload
      state.wholesaleProductsLoading = loading
    },
    setNestedWholesaleProducts: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const isExist = state.wholesaleProductNestedList.findIndex(
        (e: TTableNestedItem) => e.id === id && e.level === level
      )
      if (isExist === -1) {
        const updatedProductNestedList = [...state.wholesaleProductNestedList]
        updatedProductNestedList.push(newItem)
        state.wholesaleProductNestedList = updatedProductNestedList
      }
    },
  },
})

export default wholesaleProductsSlice.reducer

export const {
  setWholesaleProductsList,
  setWholesaleProductsFilterOptions,
  setShowManageModal,
  setShowFilterDrawer,
  setShowDeleteModal,
  setSelectedProduct,
  setNestedWholesaleProducts,
  setNestedWholesaleProductsDepth,
  setWholesaleProductsLoading,
} = wholesaleProductsSlice.actions

export const selectWholesaleProductsList = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.wholesaleProductsList
export const selectWholesaleProductsFilterOptions = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.wholesaleProductsFilterOptions
export const selectShowManageModal = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.showManageModal
export const selectShowFilterDrawer = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.showFilterDrawer
export const selectShowDeleteModal = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.showDeleteModal
export const selectProduct = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.wholesaleProduct
export const selectWholesaleProductsLoading = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.wholesaleProductsLoading
export const selectProductNestedList = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.wholesaleProductNestedList
export const selectProductNestedDepth = (state: IWholesaleProductsObject) =>
  state.wholesaleProducts.depth
