// @ts-nocheck
import React, {FC} from 'react'
import {Row} from 'react-table'

type Props = {
  row: Row<any>
  visibleColumns: any
  renderRowSubComponent: any
}

const CustomRow: FC<Props> = ({row, visibleColumns, renderRowSubComponent}) => {
  const rowProps = row.getRowProps()
  const {isExpanded}: any = row

  return (
    <React.Fragment key={rowProps.key}>
      <tr {...rowProps}>
        {row.cells.map((cell, index) => {
          if (index === 0) {
            return (
              <th {...cell.getCellProps()} colSpan={2}>
                {cell.render('Cell')}
              </th>
            )
          }
          if (cell.column.id === 'actions') {
            return (
              <td {...cell.getCellProps()} className='p-2'>
                <div className='d-flex justify-content-center gap-1 md:flex-column'>
                  {cell.render('Cell')}
                </div>
              </td>
            )
          }
          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
        })}
      </tr>
      {isExpanded ? (
        <tr>
          <td colSpan={visibleColumns.length}>{renderRowSubComponent({row})}</td>
        </tr>
      ) : null}
    </React.Fragment>
  )
}

export {CustomRow}
