export type TColor = {
  sub_colors: any
  id?: string | number
  parent_id: string | number
  color_code: string
  color_short_name: string
  is_active: boolean
  contents: Array<{
    language: string
    name: string
  }>
}

export const initialColor: TColor = {
  parent_id: '',
  color_code: '',
  color_short_name: '',
  is_active: true,
  sub_colors: [],
  contents: [
    {
      language: 'tr',
      name: '',
    },
    {
      language: 'ar',
      name: '',
    },
    {
      language: 'en',
      name: '',
    },
  ],
}

export interface IColor {
  showManageModal: boolean
  showFilterDrawer: boolean
  showDeleteModal: boolean
  colorsList: Array<TColor>
  color: any
  colorsFilterOptions: Array<any>
  depth: {
    level: number
    url: string
  }
  colorsLoading: boolean
  colorNestedList: any[]
}

export interface IColorObject {
  colors: IColor
}

export const initialState: IColor = {
  showManageModal: false,
  showFilterDrawer: false,
  showDeleteModal: false,
  colorsList: [],
  color: initialColor,
  colorsFilterOptions: [],
  colorsLoading: false,
  colorNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
}
