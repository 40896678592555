import React from 'react'
import {useIntl} from 'react-intl'

const styles = {
  borderRadius: '0.5rem',
  backgroundColor: '#fff',
  color: '#7e8299',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
  display: 'flex',
  alignItems: 'center',
  zIndex: 1000,
}

const ProgressIndicator = () => {
  const intl = useIntl()
  return (
    <div style={{...styles, position: 'absolute', textAlign: 'center'}} className='shadow'>
      <div className='spinner-border text-primary' role='status' />
      <span className='ms-5 fs-5'>{intl.formatMessage({id: 'PROCESSING'})}...</span>
    </div>
  )
}

export default ProgressIndicator
