import {apiSlice} from 'app/api/apiSlice'

const DISPLAY_NAME = 'pages'
const API_ROUTE = '/panel/pages'
const API_ROUTE_WIDGETS = '/panel/pages/widgets'

export const pagesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [DISPLAY_NAME],
  endpoints: (builder: any) => ({
    getWidgets: builder.query({
      query: (params: any) => {
        return {
          url: API_ROUTE_WIDGETS,
          params: params,
        }
      },
      providesTags: [DISPLAY_NAME],
    }),
    getPages: builder.query({
      query: (params: any) => {
        return {
          url: API_ROUTE,
          params: params,
        }
      },
      providesTags: [DISPLAY_NAME],
    }),
    getPageDetails: builder.query({
      query: (id: string) => `${API_ROUTE}/detail/${id}`,
      providesTags: [DISPLAY_NAME],
    }),
    createPage: builder.mutation({
      query: (body: any) => ({
        url: API_ROUTE,
        method: 'POST',
        body,
      }),
      invalidatesTags: [DISPLAY_NAME],
    }),
    editPage: builder.mutation({
      query: (body: {id: any}) => ({
        url: `${API_ROUTE}/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [DISPLAY_NAME],
    }),
    deletePage: builder.mutation({
      query: (id: any) => ({
        url: `${API_ROUTE}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [DISPLAY_NAME],
    }),
  }),
})

export const {
  useGetPagesQuery,
  useGetPageDetailsQuery,
  useCreatePageMutation,
  useEditPageMutation,
  useDeletePageMutation,
  useGetWidgetsQuery,
} = pagesApiSlice
