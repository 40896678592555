// @ts-nocheck

export const unionBy = (arr1: any, arr2: any, key: any) => {
  var result = arr2.concat(arr1).filter(function (this: any, o: any) {
    return this[o[key]] ? false : (this[o[key]] = true)
  }, {})
  return result
}

export const getNameWithLocale = (contents: any, locale: any) => {
  const filteredContents =
    Array.isArray(contents) && contents?.filter((value: any) => value?.name?.length > 0)
  const filteredContent =
    Array.isArray(filteredContents) &&
    filteredContents?.find((item: any) => item?.language === locale)
  if (filteredContent === undefined) {
    return filteredContents && filteredContents.length > 0 ? filteredContents[0]?.name : ''
  } else {
    return filteredContent?.name
  }
}

export const getSlugWithLocale = (contents: any, locale: any) => {
  const filteredContents =
    Array.isArray(contents) && contents?.filter((value: any) => value?.slug?.length > 0)
  const filteredContent =
    Array.isArray(filteredContents) &&
    filteredContents?.find((item: any) => item?.language === locale)
  if (filteredContent === undefined) {
    return filteredContents && filteredContents.length > 0 ? filteredContents[0]?.slug : ''
  } else {
    return filteredContent?.slug
  }
}

export const RenderIf = ({children, isTrue}: {children: any; isTrue: boolean}) => {
  return isTrue ? children : null
}

export const getContents = (initial: any, data: any) => {
  if (data && data.length === 3) {
    return data
  }
  if (data && data.length < 3) {
    return unionBy(initial, data, 'language')
  } else {
    return initial
  }
}

export const removeEmpty = (data: Array<any>) => {
  const clean: Array<any> = []
  data?.forEach(([key, value]) => {
    if (value.length > 0 || !(value === '')) clean.push([key, value])
  })
  return Object.fromEntries(clean)
}

export const getFiltersParams = (filters: any) => {
  const entries = Object.entries(filters)
  const obj = entries.map(([key, value]) => {
    if (value instanceof Array) return [key, value?.join(',')]
    else return [key, value]
  })
  return removeEmpty(obj)
}

export const joinFilterItem = (filter: Array<string>) => {
  return filter?.join(',')
}

export const stringToArray = (filter: string) => {
  return filter?.split(',')
}

export function extractExistingParams(searchParams) {
  const entries = Array.from(searchParams.entries())
  // eslint-disable-next-line no-sequences
  return entries.reduce((acc, a) => ((acc[a[0]] = acc[a[0]] || []).push(a[1]), acc), {})
}

export function removeExistingParamsArrayValue(searchParams, key, value) {
  const existingParams = extractExistingParams(searchParams)
  if (existingParams[key]) {
    existingParams[key] = existingParams[key].filter((v) => v !== value)
  }
  if (existingParams[key].length === 0) {
    delete existingParams[key]
  }
  return existingParams
}

export const dataForSelectBannerWidth = [
  {value: '33.33px', label: '1'},
  {value: '66.66px', label: '2'},
  {value: '99.99px', label: '3'},
  {value: '133.32px', label: '4'},
  {value: '166.65px', label: '5'},
  {value: '199.98px', label: '6'},
  {value: '233.31px', label: '7'},
  {value: '266.64px', label: '8'},
  {value: '299.97px', label: '9'},
  {value: '333.3px', label: '10'},
  {value: '366.63px', label: '11'},
  {value: '399.96px', label: '12'},
]

export const dataForSelectBannerDesktopWidth = [
  {value: '50px', label: '1'},
  {value: '100px', label: '2'},
  {value: '150px', label: '3'},
  {value: '200px', label: '4'},
  {value: '250px', label: '5'},
  {value: '300px', label: '6'},
  {value: '350px', label: '7'},
  {value: '400px', label: '8'},
  {value: '450px', label: '9'},
  {value: '500px', label: '10'},
  {value: '550px', label: '11'},
  {value: '600px', label: '12'},
]

export const getWidthBasedOnGrid = (options: any, grid: string) => {
  const isExist = options?.findIndex((i) => +i.label === +grid)
  if (isExist > -1) {
    return options[isExist].value
  } else {
    return options[11].value
  }
}

export const getButtonIcon = (icon: string) => {
  switch (icon) {
    case 'pencil':
      return 'fa-pen-to-square'
    case 'trash':
      return 'fa-trash'
    case 'plus':
      return 'fa-plus'
    case 'money':
      return 'fa-coins'
    case 'list':
      return 'fa-list'
    case 'eye':
      return 'fa-eye'
    default:
      return 'fa-question'
  }
}
