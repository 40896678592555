import {FC} from 'react'

type Props = {
  title: string
  routeTitle: string
}

const PageTitle: FC<Props> = (props) => {
  const {title, routeTitle} = props
  return (
    <div className='mx-2'>
      <h3 style={{color: '#3F4254'}}>{title}</h3>
      <h6 style={{color: '#B5B5C3'}}>{routeTitle}</h6>
    </div>
  )
}

export {PageTitle}
