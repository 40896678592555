import {createSlice} from '@reduxjs/toolkit'
import {IProductColorsObject, initialProductColor, initialState} from './_models'

const productColorsSlice = createSlice({
  name: 'productColors',
  initialState,
  reducers: {
    setProductColorsList: (state, action) => {
      const {data} = action.payload
      state.productColorsList = data
    },
    setProductColorsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.productColorsFilterOptions = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.productColor = initialProductColor
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setShowProductColorUpdatePriceModal: (state, action) => {
      const {show} = action.payload
      state.showProductColorUpdatePrice = show
    },
    setSelectedProductColor: (state, action) => {
      const {data} = action.payload
      state.productColor = data
    },
    setNestedProductColorsDepth: (state, action) => {
      const {level, url} = action.payload
      state.depth = {
        level,
        url,
      }
    },
    setProductColorsLoading: (state, action) => {
      const {loading} = action.payload
      state.productColorsLoading = loading
    },
    setNestedProductColors: (state, action) => {
      const {data, id, level, url, ...args} = action.payload
      const newItem = {
        ...args,
        data,
        id,
        level,
        url,
      }
      const isExist = state.productColorNestedList.findIndex(
        (e: any) => e.id === id && e.level === level
      )
      if (isExist === -1) {
        const updatedProductColorNestedList = [...state.productColorNestedList]
        updatedProductColorNestedList.push(newItem)
        state.productColorNestedList = updatedProductColorNestedList
      }
    },
  },
})

export const {
  setShowDeleteModal,
  setProductColorsList,
  setShowManageModal,
  setSelectedProductColor,
  setShowFilterDrawer,
  setProductColorsFilterOptions,
  setNestedProductColors,
  setNestedProductColorsDepth,
  setProductColorsLoading,
  setShowProductColorUpdatePriceModal,
} = productColorsSlice.actions

export default productColorsSlice.reducer

export const selectShowProductColorUpdatePrice = (state: IProductColorsObject) =>
  state.productColors.showProductColorUpdatePrice
export const selectShowManageModal = (state: IProductColorsObject) =>
  state.productColors.showManageModal
export const selectProductColorsList = (state: IProductColorsObject) =>
  state.productColors.productColorsList
export const selectProductColor = (state: IProductColorsObject) => state.productColors.productColor
export const selectProductColorsFilterOptions = (state: IProductColorsObject) =>
  state.productColors.productColorsFilterOptions
export const selectShowFilterDrawer = (state: IProductColorsObject) =>
  state.productColors.showFilterDrawer
export const selectShowDeleteModal = (state: IProductColorsObject) =>
  state.productColors.showDeleteModal
export const selectProductColorsLoading = (state: IProductColorsObject) =>
  state.productColors.productColorsLoading
export const selectProductColorNestedList = (state: IProductColorsObject) =>
  state.productColors.productColorNestedList
export const selectProductColorNestedDepth = (state: IProductColorsObject) =>
  state.productColors.depth
