import clsx from 'clsx'
import {RenderIf} from 'helpers'
import {FC} from 'react'

type Props = {
  placeholder: string
  name?: string
  value?: any
  onChange: any
  onBlur?: any
  className?: string
  disabled?: boolean
  label: string
  required?: boolean
  inputProps?: object
  type?: string
} & React.HTMLAttributes<HTMLInputElement>

const InputField: FC<Props> = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  className,
  disabled,
  required,
  inputProps,
  type,
  ...restProps
}) => {
  return (
    <div className='vstack my-6'>
      <RenderIf isTrue={Boolean(label)}>
        <label className={clsx(required && 'required', 'form-label fw-bold fs-6')}>{label}</label>
      </RenderIf>
      <input
        {...restProps}
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={clsx('form-control', className)}
        autoComplete='off'
        disabled={disabled}
        {...inputProps}
      />
    </div>
  )
}

export default InputField
