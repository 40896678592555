import {FC} from 'react'
import './FileUpload.scss'
import CircleProgressIndicator from 'components/CircleProgressIndicator'

type Props = {
  name: string
  progress: number
  isFailed: boolean
  isLastItem: boolean
}

const FileUploadItem: FC<Props> = ({name, isLastItem, progress, isFailed}) => {
  return (
    <>
      <li className='file-item'>
        <div>
          {progress < 100 && <CircleProgressIndicator value={progress} />}
          {progress === 100 && !isFailed && <i className='fa-solid fa-circle-check'></i>}
          {progress === 100 && isFailed && <i className='fa-regular fs-2 fa-circle-exclamation'/>}
        </div>
        <div className={`file-item-name`}>
          <p>{name}</p>
        </div>
      </li>
      {!isLastItem && <hr className='file-item-divider' />}
    </>
  )
}

FileUploadItem.defaultProps = {
  isLastItem: false,
}

export default FileUploadItem
