export type TUser = {
  id?: number
  name: string
  surname: string
  email: string
  country_code: string
  phone: string
  role: any
}

export const initialUser: TUser = {
  name: '',
  surname: '',
  email: '',
  country_code: '',
  phone: '',
  role: undefined,
}

export interface IUsers {
  usersFilterOptions: Array<any>
  showMangeModal: boolean
  showFilterDrawer: boolean
  usersList: Array<TUser>
  user: TUser
}

export interface IUsersObject {
  users: IUsers
}

export const initialState: IUsers = {
  showFilterDrawer: false,
  showMangeModal: false,
  usersList: [],
  user: initialUser,
  usersFilterOptions: [],
}
