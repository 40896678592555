import {useState} from 'react'
import ModalLanguageTab from 'components/common/LanguageTabModal'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import {resetSelectedShape, updateSelectedSettings} from 'pages/pages/core/slice'
import {initialSettings} from 'pages/pages/core/_models'

const Header = ({
  onFrameChange,
  onLangChange,
}: {
  onFrameChange: (frame: string) => void
  onLangChange: any
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [frame, setFrame] = useState('mobile')
  return (
    <div className='d-flex justify-content-between align-items-center p-5 shadow-sm bg-white rounded'>
      <div
        className='btn btn-sm btn-icon btn-active-color-primary'
        onClick={() => {
          dispatch(updateSelectedSettings({data: initialSettings}))
          dispatch(resetSelectedShape())
          navigate(`/pages/list?page=1`)
        }}
      >
        <i className='fa-solid fa-arrow-left fs-2' />
      </div>
      <div className='d-flex'>
        <div
          onClick={() => {
            setFrame('mobile')
            onFrameChange('mobile')
          }}
        >
          <i
            className={clsx(
              'fa-solid fa-mobile-screen-button fs-4',
              frame === 'mobile' && 'text-secondary'
            )}
          />
        </div>
        <div
          className='ms-3'
          onClick={() => {
            setFrame('web')
            onFrameChange('web')
          }}
        >
          <i className={clsx('fa-solid fa-desktop fs-4', frame === 'web' && 'text-secondary')} />
        </div>
      </div>
      <ModalLanguageTab onLangChange={onLangChange} />
    </div>
  )
}

export default Header
