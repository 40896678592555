// @ts-nocheck
import {useMemo, useRef, useState, useEffect} from 'react'
import {generateRandomId} from 'utils/string'
import FileUploadItem from './FileUploadItem'
import useDragAndDrop from 'hooks/useDragAndDrop'
import './FileUpload.scss'

export const updateStatus = (key, value) => {
  return {
    uploading: false,
    done: false,
    failed: false,
    partialDone: false,
    [key]: value,
  }
}

const FileUpload = ({
  id,
  error,
  dragDropMessage,
  fileSelectMessage,
  onUploadFiles,
  uploadedFiles,
  onRemoveFile,
  isMulti,
}) => {
  const fileUploadRef = useRef()

  const inputId = useMemo(() => id || generateRandomId(), [id])

  const [uploadStatus, setUploadStatus] = useState({
    uploading: false,
    done: false,
    failed: false,
    partialDone: false,
  })
  const [uploadedMessage, setUploadedMessage] = useState('')

  useEffect(() => {
    const uFilesLength = uploadedFiles.length
    let _isUploading = false

    for (let i = 0; i < uFilesLength; i += 1) {
      if (uploadedFiles[i].progress < 100) {
        _isUploading = true
      }
    }

    setUploadStatus(updateStatus('uploading', _isUploading))

    if (!_isUploading) {
      const uploadFailed = []
      const uploadDone = []
      for (let i = 0; i < uFilesLength; i += 1) {
        if (uploadedFiles[i].isFailed) {
          uploadFailed.push(i)
        } else {
          uploadDone.push(i)
        }
      }

      if (uploadFailed.length === uFilesLength) {
        setUploadStatus(updateStatus('failed', true))
        setUploadedMessage('Upload failed')
      } else if (uploadDone.length === uFilesLength) {
        setUploadStatus(updateStatus('done', true))
        setUploadedMessage(
          `${uploadDone.length} ${uploadDone.length > 1 ? 'files' : 'file'} uploaded`
        )
      } else {
        setUploadStatus(updateStatus('partialDone', true))
        setUploadedMessage(
          `${uploadFailed.length} ${uploadFailed.length > 1 ? 'uploads' : 'upload'} failed`
        )
      }
    }
  }, [uploadedFiles])

  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop()

  const onDropHandler = (e) => {
    e.preventDefault()
    setDragOver(false)
    const filesObj = {}
    Object.values(e.dataTransfer.files).forEach((item) => {
      const {name} = item
      filesObj[name] = item
    })
    onUploadFiles(filesObj)
  }

  const onSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const filesObj = {}
    Object.values(e.target.files).forEach((item) => {
      const {name} = item
      filesObj[name] = item
    })
    onUploadFiles(filesObj)
  }

  return (
    <div className={`${error && 'error'}`}>
      <input
        ref={fileUploadRef}
        type='file'
        id={inputId}
        multiple={isMulti}
        className='input-file'
        onChange={onSelectHandler}
        onClick={(e) => {
          e.target.value = null
        }}
      />

      {uploadedFiles.length === 0 && (
        <div
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDropHandler}
          className={`input-file-box ps-10 ${dragOver && 'drag-over'}`}
        >
          <i className='fa-solid fa-cube fs-1 me-10' />
          <div>
            <p className='drag-drop-message'>{dragDropMessage}</p>
            <input
              type='button'
              value={fileSelectMessage}
              className={'select-file-message'}
              onClick={() => fileUploadRef.current.click()}
            />
          </div>
        </div>
      )}

      {uploadedFiles.length > 0 && (
        <div className='upload-box'>
          {uploadStatus.uploading ? (
            <p className='upload-message'>{`Uploading ${uploadedFiles.length} files...`}</p>
          ) : (
            <div className='uploaded-message'>
              <div
                className={`uploaded-status-message ${
                  uploadStatus.done && 'uploaded-done-message'
                }`}
              >
                <span>{uploadedMessage}</span>
              </div>
              <div className='upload-more-button'>
                <input
                  id='test-upload-more-button'
                  type='button'
                  value='Upload more files'
                  className='btn btn-secondary text-white btn-sm'
                  onClick={() => fileUploadRef.current.click()}
                />
              </div>
            </div>
          )}

          <ul className='upload-items-list'>
            {uploadedFiles.map(({name, progress, isFailed}, fileIndex) => (
              <FileUploadItem
                key={name}
                name={name}
                isFailed={isFailed}
                progress={progress}
                isLastItem={uploadedFiles.length - 1 === fileIndex}
                onRemoveFile={onRemoveFile}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default FileUpload
