import {
  RenderIf,
  dataForSelectBannerDesktopWidth,
  dataForSelectBannerWidth,
  getWidthBasedOnGrid,
} from 'helpers'
import {
  selectSelectedBanners,
  selectSelectedBoxes,
  selectSelectedStories,
  selectSelectedText,
  setSelectedBannerForEdit,
  setSelectedBoxForEdit,
  setSelectedStoryForEdit,
  setSelectedTextForEdit,
  setShowBannerSettings,
  setShowBoxSettings,
  setShowStorySettings,
  setShowTextSettings,
} from 'pages/pages/core/slice'
import {Draggable, Droppable} from 'react-beautiful-dnd'
import {useDispatch, useSelector} from 'react-redux'
import Section from './Section'

const SectionContainer = ({
  activeSectionId,
  sectionId,
  frame,
  lang,
  setActiveStoryId,
  setActiveBannerId,
  setActiveBoxId,
  activeStoryId,
  activeBannerId,
  setSelectedWidget,
  activeBoxId,
  setActiveTextId,
  activeTextId,
}: {
  activeSectionId: any
  sectionId: any
  frame: any
  lang: any
  setActiveStoryId: any
  setSelectedWidget: any
  setActiveBannerId: any
  setActiveBoxId: any
  activeStoryId: any
  activeBannerId: any
  activeBoxId: any
  setActiveTextId: any
  activeTextId: any
}) => {
  const dispatch = useDispatch()

  const selectedStories = useSelector(selectSelectedStories)
  const selectedBanners = useSelector(selectSelectedBanners)
  const selectedBoxes = useSelector(selectSelectedBoxes)
  const selectedText = useSelector(selectSelectedText)

  return (
    <Droppable droppableId={`section-${sectionId}`} type='droppableSubItem' direction='horizontal'>
      {(provided) => (
        <div ref={provided.innerRef}>
          <Section
            index={sectionId}
            style={{}}
            frame={frame}
            lang={lang}
            className={`section-container ${frame === 'mobile' ? 'w-430px' : 'w-630px'}`}
          >
            {selectedStories?.map((item: any, swIndex: number) => {
              if (item.frame === frame && item.lang === lang && item.section === sectionId) {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={swIndex}>
                    {(provided) => (
                      <div
                        onMouseDown={() => {
                          setSelectedWidget('story')
                        }}
                        onClick={() => {
                          setActiveStoryId(item.id)
                          setActiveBannerId('')
                          setActiveBoxId('')
                          setActiveTextId('')
                          dispatch(setSelectedStoryForEdit({id: item.id}))
                          dispatch(setShowStorySettings({show: true}))
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`story-widget ${
                          activeStoryId === item.id && activeSectionId === sectionId && 'active'
                        }`}
                      >
                        <div className='story-widget-inner '>
                          <div className='story-widget-inner__img'>
                            <RenderIf isTrue={Boolean(item.media)}>
                              <img
                                className='w-70px h-70px rounded-circle'
                                src={item.media}
                                alt='story widget media'
                              />
                            </RenderIf>
                            <RenderIf isTrue={!Boolean(item.media)}>
                              <div className='w-70px h-70px rounded-circle bg-light-dark' />
                            </RenderIf>
                          </div>
                          <div className='story-widget-inner__title'>
                            {item.title && item.title !== '' ? item.title : 'Label'}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              } else return null
            })}
            {selectedBanners?.map((item: any, bIndex: number) => {
              if (item.frame === frame && item.lang === lang && item.section === sectionId) {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={bIndex}>
                    {(provided) => (
                      <div
                        onMouseDown={() => {
                          setSelectedWidget('banner')
                        }}
                        onClick={() => {
                          setActiveBannerId(item.id)
                          setActiveStoryId('')
                          setActiveBoxId('')
                          setActiveTextId('')
                          dispatch(setSelectedBannerForEdit({id: item.id}))
                          dispatch(setShowBannerSettings({show: true}))
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`banner-widget ${
                          activeBannerId === item.id && activeSectionId === sectionId && 'active'
                        }`}
                      >
                        <div className='banner-widget-inner me-2 mb-2 '>
                          <RenderIf isTrue={Boolean(item.media)}>
                            <img
                              width={getWidthBasedOnGrid(
                                frame === 'mobile'
                                  ? dataForSelectBannerWidth
                                  : dataForSelectBannerDesktopWidth,
                                item.width
                              )}
                              height={item.height || '140px'}
                              style={{borderRadius: '10px'}}
                              src={item.media}
                              className={`banner-widget-image ${
                                activeBannerId === item.id &&
                                activeSectionId === sectionId &&
                                'active'
                              }`}
                              alt='banner widget media'
                            />
                          </RenderIf>
                          <RenderIf isTrue={!Boolean(item.media)}>
                            <div
                              className={`banner-widget-image-template ${
                                activeBannerId === item.id &&
                                activeSectionId === sectionId &&
                                'active'
                              }`}
                            >
                              Add Photo
                            </div>
                          </RenderIf>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              } else return null
            })}
            {selectedBoxes?.map((item: any, boxIndex: number) => {
              if (item.frame === frame && item.lang === lang && item.section === sectionId) {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={boxIndex}>
                    {(provided) => (
                      <div
                        onMouseDown={() => {
                          setSelectedWidget('boxes')
                        }}
                        onClick={() => {
                          setActiveBoxId(item.id)
                          setActiveStoryId('')
                          setActiveBannerId('')
                          setActiveTextId('')
                          dispatch(setSelectedBoxForEdit({id: item.id}))
                          dispatch(setShowBoxSettings({show: true}))
                        }}
                      >
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`box-widget ${
                            activeBoxId === item.id && activeSectionId === sectionId && 'active'
                          }`}
                        >
                          <div className='box-widget-inner'>
                            <RenderIf isTrue={Boolean(item.media)}>
                              <img
                                width='64px'
                                height='64px'
                                src={item.media}
                                alt='box widget media'
                              />
                              <span className={`box-widget-title mt-3`}>
                                {item.title && item.title !== '' ? item.title : 'Label'}
                              </span>
                            </RenderIf>
                            <RenderIf isTrue={!Boolean(item.media)}>
                              <div
                                className={`box-widget-image-template ${
                                  activeBoxId === item.id &&
                                  activeSectionId === sectionId &&
                                  'active'
                                }`}
                              >
                                Add Photo
                              </div>
                            </RenderIf>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              } else return null
            })}
            {selectedText?.map((item: any, textIndex: number) => {
              if (item.frame === frame && item.lang === lang && item.section === sectionId) {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={textIndex}>
                    {(provided) => (
                      <>
                        <div
                          onMouseDown={() => {
                            setSelectedWidget('text')
                          }}
                          onClick={() => {
                            setActiveTextId(item.id)
                            setActiveStoryId('')
                            setActiveBannerId('')
                            setActiveBoxId('')
                            dispatch(setSelectedTextForEdit({id: item.id}))
                            dispatch(setShowTextSettings({show: true}))
                          }}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`text-widget ${
                              activeTextId === item.id && activeSectionId === sectionId && 'active'
                            }`}
                          >
                            <div className=''>
                              {item.body ? (
                                <div dangerouslySetInnerHTML={{__html: item.body}} />
                              ) : (
                                <p>Click here to type in the editor</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Draggable>
                )
              } else return null
            })}
            {provided.placeholder}
          </Section>
        </div>
      )}
    </Droppable>
  )
}

export default SectionContainer
