// @ts-nocheck
import {pageScrollIntoView} from 'assets/ts/_utils'
import clsx from 'clsx'
import {KTCardBody} from 'helpers'
import {FC, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Column, ColumnInstance, Row, useExpanded, useTable} from 'react-table'
import {reorderByArray} from 'utils/array'
import './DataTable.scss'
import ColumnsAdjustment from './components/ColumnsAdjustment'
import {CustomRow} from './components/CustomRow'
import Pagination from './components/Pagination'
interface IProps {
  id: string
  columns: ReadonlyArray<Column<any>>
  data: any[]
  className?: string
  theadClassName?: string
  tbodyClassName?: string
  renderRowSubComponent?: any
  pagination?: any
  onPageChange?: (page: number) => void
  searchParams?: any
  setSearchParams?: any
}

enum ORDER {
  ASC = 'asc',
  DESC = 'desc',
  UNDEFINED = 'undefined',
}

const DataTable: FC<IProps> = ({
  id,
  columns: allColumns,
  data,
  className,
  theadClassName,
  tbodyClassName,
  renderRowSubComponent,
  pagination,
  onPageChange,
  searchParams,
  setSearchParams,
}) => {
  const intl = useIntl()
  const [localStorageData, setLocalStorageData] = useState<{
    hiddenFields: Array<string>
    allColumnsOrder: Array<string>
  }>({hiddenFields: [], allColumnsOrder: []})
  const [filteredColumn, setFilteredColumn] = useState<Array<any>>([])
  const [reRender, setReRender] = useState<boolean>(true)

  const columns = useMemo<ReadonlyArray<Column<any>>>(() => {
    const arrangedColumns = reorderByArray(allColumns, localStorageData.allColumnsOrder)

    return arrangedColumns.filter(
      (column: any) => !localStorageData.hiddenFields.includes(column.Header)
    )
  }, [allColumns, localStorageData])

  useEffect(() => {
    const data = getDataFromLocalStorage(id)
    setLocalStorageData(data)

    const arrangedColumns = reorderByArray(allColumns, data.allColumnsOrder)
    const newColumns = arrangedColumns.map((column: any) => {
      return {
        header: column.Header,
        isHidden:
          data.hiddenFields.length > 0
            ? data.hiddenFields.some((item: string) => item === column.Header)
            : false,
      }
    })
    setFilteredColumn(newColumns)
    setReRender(false)
  }, [allColumns, id, reRender])

  const {getTableProps, getTableBodyProps, headers, prepareRow, rows, visibleColumns} = useTable(
    {
      columns,
      data,
    },
    useExpanded
  )

  const handleOrders = (item: string) => {
    const [fieldName, order] = item.split(':')
    if (order === ORDER.ASC) {
      return `${fieldName}:${ORDER.DESC}`
    }
    if (order === ORDER.DESC) {
      return `${fieldName}:${ORDER.UNDEFINED}`
    }
  }

  const sortClickHandler = (columnName: string) => {
    const newParam = `${columnName}:${ORDER.ASC}`
    const sorts = searchParams.get('sorts')
    if (!sorts) {
      searchParams.set('sorts', newParam)
      setSearchParams(searchParams)
    } else {
      const splitArray = sorts.split(',')
      const isNameExist = splitArray.find((f) => f.includes(columnName))

      if (!isNameExist) {
        splitArray.push(newParam)
        searchParams.set('sorts', splitArray.join(','))
        setSearchParams(searchParams)
      } else {
        const newSorts = splitArray.map((item) => {
          if (item.includes(columnName)) {
            return handleOrders(item)
          } else return item
        })
        const filterByUndefined = newSorts.filter((f) => !f.includes(ORDER.UNDEFINED))
        if (filterByUndefined.length > 0) {
          searchParams.set('sorts', filterByUndefined.join(','))
          setSearchParams(searchParams)
        } else {
          searchParams.delete('sorts')
          setSearchParams(searchParams)
        }
      }
    }
  }

  const handleSortPosition = (columnName: string) => {
    const sortParam = searchParams.get('sorts')
    if (!sortParam) return
    const splitSort = sortParam.split(',')
    const sortPosition = splitSort?.findIndex((sortName: string) => sortName.includes(columnName))
    if (sortPosition > -1) {
      return (
        <span className='badge badge-circle badge-secondary ms-3 text-white'>
          {+sortPosition + 1}
        </span>
      )
    }
    return
  }

  const handleGetSortIcon = (columnName: string) => {
    let order = ORDER.UNDEFINED
    const sorts = searchParams.get('sorts')
    if (sorts) {
      sorts.split(',').forEach((item: string) => {
        if (item.includes(columnName)) {
          order = item.split(':')[1]
        }
      })
      if (order !== ORDER.UNDEFINED) {
        if (order === ORDER.DESC) {
          return <i className='fa-solid fa-sort-down text-white' />
        }
        if (order === ORDER.ASC) {
          return <i className='fa-solid fa-sort-up text-white' />
        }
      } else {
        return <i className='fa-solid fa-sort' />
      }
    } else {
      return <i className='fa-solid fa-sort' />
    }
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id={`table_${id}`}
          className={`table table-sm align-middle table-striped table-bordered text-center ${className}`}
          {...getTableProps()}
        >
          <thead className={`${theadClassName} custom-table-head`}>
            <tr className='text-center align-middle'>
              <th>
                <ColumnsAdjustment
                  columns={filteredColumn}
                  tableId={id}
                  allHiddenFields={localStorageData.hiddenFields}
                  onSubmitHandler={() => setReRender(true)}
                />
              </th>
              {headers.map((column: ColumnInstance<any>) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className={clsx('text-nowrap text-white fw-bolder fs-7 text-uppercase')}
                  >
                    {intl.formatMessage({id: column.render('Header')})}
                    {column.sortable && (
                      <>
                        <span
                          className='ms-3 cursor-pointer'
                          onClick={() => sortClickHandler(column.name)}
                        >
                          {handleGetSortIcon(column.name)}
                        </span>
                        {handleSortPosition(column.name)}
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody
            className={`text-gray-600 fw-bold ${tbodyClassName} custom-table-body`}
            {...getTableBodyProps()}
          >
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    visibleColumns={visibleColumns}
                    renderRowSubComponent={renderRowSubComponent}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={visibleColumns.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'NO_MATCHING_RECORDS_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {rows.length > 0 && pagination && (
          <div className='pagination'>
            <Pagination
              className='pagination-bar'
              currentPage={pagination?.current_page}
              totalCount={pagination?.total}
              pageSize={pagination?.per_page}
              onPageChange={(page: any) => {
                onPageChange && onPageChange(page)
                pageScrollIntoView()
              }}
            />
          </div>
        )}
      </div>
    </KTCardBody>
  )
}

export default DataTable

const getDataFromLocalStorage = (id: string) => {
  const storedHiddenFields = localStorage.getItem(id)
  if (storedHiddenFields !== null) {
    return JSON.parse(storedHiddenFields)
  } else {
    return {
      allColumnsOrder: [],
      hiddenFields: [],
    }
  }
}
