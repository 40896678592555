import React, {useState} from 'react'
import {createPortal} from 'react-dom'
import {useSelector} from 'react-redux'
import {Modal} from 'react-bootstrap'
import Header from './Header'
import {selectCurrentUser} from 'features/auth/authSlice'
import {TLanguageTabModal} from 'components/common/LanguageTabModal/_models'

type Props = {
  show: boolean
  handleClose: () => void
  children: React.ReactElement
  headerTitle: string
  id: string
  isMultiLanguage: boolean
  modalWidth?: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditModal = ({
  show,
  handleClose,
  children,
  headerTitle,
  id,
  isMultiLanguage,
  modalWidth = 'mw-900px',
}: Props) => {
  const user = useSelector(selectCurrentUser)
  const [lang, setLang] = useState<TLanguageTabModal>('tr')
  const newChildren = React.cloneElement(children, {...{lang: lang, setLang: setLang}})

  return createPortal(
    <Modal
      id={`modal_${id}`}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered ${modalWidth}`}
      show={show}
      backdrop
    >
      <Header
        handleClose={() => {
          handleClose()
          setLang('tr')
        }}
        onLangChange={setLang}
        adminName={`${user.name} ${user.surname}`}
        title={headerTitle}
        isMultiLanguage={isMultiLanguage}
      />
      <div className='modal-body'>{show && newChildren}</div>
    </Modal>,
    modalsRoot
  )
}

export default EditModal
