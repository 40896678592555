import {createSlice} from '@reduxjs/toolkit'
import {IDailySellTypesObject, initialState} from './_models'

const dailySellSlice = createSlice({
  name: 'dailySell',
  initialState,
  reducers: {
    setDailySellList: (state, action) => {
      const {data} = action.payload
      state.dailySellList = data
    },
    setDailySellFilterOptions: (state, action) => {
      const {data} = action.payload
      state.dailySellFilterOptions = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
  },
})

export const {setDailySellList, setDailySellFilterOptions, setShowFilterDrawer} =
  dailySellSlice.actions

export default dailySellSlice.reducer

export const selectDailySellList = (state: IDailySellTypesObject) => state.dailySell.dailySellList
export const selectShowFilterDrawer = (state: IDailySellTypesObject) =>
  state.dailySell.showFilterDrawer
export const selectDailySellFilterOptions = (state: IDailySellTypesObject) =>
  state.dailySell.dailySellFilterOptions
