import {apiSlice} from 'app/api/apiSlice'

const DISPLAY_NAME = 'statistics'
const API_ROUTE = '/panel/orders/statics/all'

export const statisticsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [DISPLAY_NAME],
  endpoints: (builder: any) => ({
    getStatistics: builder.query({
      query: (params: any) => {
        return {
          url: API_ROUTE,
          params: params,
        }
      },
      providesTags: [DISPLAY_NAME],
    }),
  }),
})

export const {useGetStatisticsQuery} = statisticsApiSlice
