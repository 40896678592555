import {createSlice} from '@reduxjs/toolkit'
import {IBadgeObject, initialBadge, initialState} from './_models'

const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    setBadgesList: (state, action) => {
      const {data} = action.payload
      state.badgesList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.badge = initialBadge
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedBadge: (state, action) => {
      const {data} = action.payload
      state.badge = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setBadgesFilterOptions: (state, action) => {
      const {data} = action.payload
      state.badgesFilterOptions = data
    },
  },
})

export const {
  setBadgesList,
  setShowManageModal,
  setSelectedBadge,
  setShowDeleteModal,
  setBadgesFilterOptions,
  setShowFilterDrawer,
} = badgesSlice.actions

export default badgesSlice.reducer

export const selectShowManageModal = (state: IBadgeObject) => state.badges.showManageModal
export const selectBadge = (state: IBadgeObject) => state.badges.badge
export const selectBadgesList = (state: IBadgeObject) => state.badges.badgesList
export const selectShowDeleteModal = (state: IBadgeObject) => state.badges.showDeleteModal
export const selectBadgesFilterOptions = (state: IBadgeObject) => state.badges.badgesFilterOptions
export const selectShowFilterDrawer = (state: IBadgeObject) => state.badges.showFilterDrawer
