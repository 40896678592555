import {FC} from 'react'

type Props = {
  tableProps: any
}

const SelectionHeader: FC<Props> = ({tableProps}) => {
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px ps-8'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check-target='#table_categories .form-check-input'
          // data-kt-check={isAllSelected}
          // checked={isAllSelected}
          // onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

export {SelectionHeader}
