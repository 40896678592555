import {TFilterOption} from 'models'

export type TSaleChannel = {
  id?: number
  name: string
  media: {
    original: string
    thumb: string
    medium: string
    large: string
  }[]
  cargo_fees: {
    country_id: number
    is_limit_active: boolean
    limit: string
    price: string
  }[]
  pay_at_door_fees: {
    country_id: number
    price: string
    limit: string
    is_limit_active: boolean
  }[]
}

export const initialSaleChannel: TSaleChannel = {
  name: '',
  media: [
    {
      original: '',
      thumb: '',
      medium: '',
      large: '',
    },
  ],
  cargo_fees: [
    {
      country_id: 0,
      is_limit_active: false,
      limit: '',
      price: '',
    },
  ],
  pay_at_door_fees: [
    {
      country_id: 0,
      price: '',
      limit: '',
      is_limit_active: false,
    },
  ],
}

export interface ISaleChannel {
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  saleChannelsList: Array<TSaleChannel>
  saleChannel: TSaleChannel
  saleChannelsFilterOptions: Array<TFilterOption>
}

export interface ISaleChannelObject {
  saleChannels: ISaleChannel
}

export const initialState: ISaleChannel = {
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  saleChannelsList: [],
  saleChannel: initialSaleChannel,
  saleChannelsFilterOptions: [],
}
