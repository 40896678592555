// @ts-nocheck
import Circle from './Circle'

const CircleProgressIndicator = ({value}) => {
  return (
    <div>
      <Circle value={value} size='sm' />
    </div>
  )
}

export default CircleProgressIndicator
