import {createSlice} from '@reduxjs/toolkit'
import {generateRandomId} from 'utils/string'
import {
  IPagesObject,
  initialBannerWidget,
  initialBoxWidget,
  initialPage,
  initialState,
  initialStoryWidget,
  initialTextWidget,
} from './_models'

const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPagesList: (state, action) => {
      const {data} = action.payload
      state.pagesList = data
    },
    setPagesFilterOptions: (state, action) => {
      const {data} = action.payload
      state.pagesFilterOptions = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.page = initialPage
      }
    },
    setShowWidgetsModal: (state, action) => {
      const {show} = action.payload
      state.showWidgetsModal = show
    },
    setWidgetType: (state, action) => {
      const {widget} = action.payload
      state.widgetType = widget
    },
    setShowStorySettings: (state, action) => {
      const {show} = action.payload
      if (show) {
        state.showStorySettings = show
        state.showBannerSettings = !show
        state.showTextSettings = !show
        state.showBoxSettings = !show
      } else {
        state.showStorySettings = show
      }
    },
    setReorderedBanners: (state, action) => {
      const {data} = action.payload
      state.selectedBanners = data
    },
    setReorderedStories: (state, action) => {
      const {data} = action.payload
      state.selectedStories = data
    },
    setReorderedBoxes: (state, action) => {
      const {data} = action.payload
      state.selectedBoxes = data
    },
    setReorderedText: (state, action) => {
      const {data} = action.payload
      state.selectedText = data
    },
    setShowBannerSettings: (state, action) => {
      const {show} = action.payload
      if (show) {
        state.showBannerSettings = show
        state.showStorySettings = !show
        state.showTextSettings = !show
        state.showBoxSettings = !show
      } else {
        state.showBannerSettings = show
      }
    },
    setShowTextSettings: (state, action) => {
      const {show} = action.payload
      if (show) {
        state.showTextSettings = show
        state.showBannerSettings = !show
        state.showStorySettings = !show
        state.showBoxSettings = !show
      } else {
        state.showTextSettings = show
      }
    },
    setShowBoxSettings: (state, action) => {
      const {show} = action.payload
      if (show) {
        state.showBoxSettings = show
        state.showBannerSettings = !show
        state.showTextSettings = !show
        state.showStorySettings = !show
      } else {
        state.showBoxSettings = show
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedPage: (state, action) => {
      const {data} = action.payload
      state.page = data
    },
    setDataForCreate: (state, action) => {
      const {data} = action.payload
      state.dataForCreate = data
    },
    setSelectedShape: (state, action) => {
      const {data, frame, lang, section, title, link, media, width, height, body} = action.payload
      const updatedSelectedText = [...state.selectedText]
      const updatedSelectedBanners = [...state.selectedBanners]
      const updatedSelectedStories = [...state.selectedStories]
      const updatedselectedBoxes = [...state.selectedBoxes]
      const id = generateRandomId()
      const newItem = {
        section: section,
        lang: lang,
        frame: frame,
        id: `${data}-${id}`,
        shape: data,
        title: title,
        link: link,
        media: media,
        width: width,
        height: height,
        body: body,
      }
      if (data === 'text') {
        updatedSelectedText.push(newItem)
        state.selectedText = updatedSelectedText
      }
      if (data === 'banner') {
        updatedSelectedBanners.push(newItem)
        state.selectedBanners = updatedSelectedBanners
      } else if (data === 'story') {
        updatedSelectedStories.push(newItem)
        state.selectedStories = updatedSelectedStories
      } else if (data === 'boxes') {
        updatedselectedBoxes.push(newItem)
        state.selectedBoxes = updatedselectedBoxes
      } else return
    },
    resetSelectedShape: (state) => {
      state.selectedStories = []
      state.selectedBanners = []
      state.selectedBoxes = []
      state.selectedText = []
      state.showStorySettings = false
      state.showBannerSettings = false
      state.showBoxSettings = false
      state.showTextSettings = false
    },
    setSelectedStoryForEdit: (state, action) => {
      const {id} = action.payload
      const storyIdx = state.selectedStories.findIndex((s) => s.id === id)
      const existingShape = state.selectedStories[storyIdx]
      const newStoryWidgetForEdit = {
        id: id,
        title: existingShape.title ?? '',
        link: existingShape.link ?? '',
        media: existingShape.media ?? '',
      }
      state.selectedStoryForEdit = newStoryWidgetForEdit
    },
    setSelectedBannerForEdit: (state, action) => {
      const {id} = action.payload
      const bannerIdx = state.selectedBanners.findIndex((s) => s.id === id)
      const existingShape = state.selectedBanners[bannerIdx]
      const newBannerWidgetForEdit = {
        id: id,
        width: existingShape.width ? existingShape.width : '12',
        height: existingShape.height ? existingShape.height : '',
        link: existingShape.link ? existingShape.link : '',
        media: existingShape.media ? existingShape.media : '',
      }
      state.selectedBannerForEdit = newBannerWidgetForEdit
    },
    setSelectedTextForEdit: (state, action) => {
      const {id} = action.payload
      const isExist = state.selectedText.findIndex((s) => s.id === id)
      if (isExist > -1) {
        const existingShape = state.selectedText[isExist]
        const newTextWidgetForEdit = {
          id: id,
          body: existingShape.body ? existingShape.body : '',
        }
        state.selectedTextForEdit = newTextWidgetForEdit
      } else {
        const newTextWidgetForEdit = {
          id: id,
          body: '',
        }
        state.selectedTextForEdit = newTextWidgetForEdit
      }
    },
    setSelectedBoxForEdit: (state, action) => {
      const {id} = action.payload
      const boxIdx = state.selectedBoxes.findIndex((s) => s.id === id)
      const existingShape = state.selectedBoxes[boxIdx]
      const newBoxWidgetForEdit = {
        id: id,
        title: existingShape.title ? existingShape.title : '',
        width: existingShape.width ? existingShape.width : '12',
        height: existingShape.height ? existingShape.height : '',
        link: existingShape.link ? existingShape.link : '',
        media: existingShape.media ? existingShape.media : '',
      }
      state.selectedBoxForEdit = newBoxWidgetForEdit
    },
    updateSelectedBoxForEdit: (state, action) => {
      const {data} = action.payload
      const newSelectedShapes = [...state.selectedBoxes]
      const objIndex = newSelectedShapes.findIndex((s) => s.id === data.id)
      newSelectedShapes[objIndex] = {...newSelectedShapes[objIndex], ...data}
      state.selectedBoxes = newSelectedShapes
    },
    updateSelectedBannerForEdit: (state, action) => {
      const {data} = action.payload
      const newSelectedShapes = [...state.selectedBanners]
      const objIndex = newSelectedShapes.findIndex((s) => s.id === data.id)
      newSelectedShapes[objIndex] = {...newSelectedShapes[objIndex], ...data}
      state.selectedBanners = newSelectedShapes
    },
    updateSelectedTextForEdit: (state, action) => {
      const {data} = action.payload
      const newSelectedShapes = [...state.selectedText]
      const objIndex = newSelectedShapes.findIndex((s) => s.id === data.id)
      newSelectedShapes[objIndex] = {...newSelectedShapes[objIndex], ...data}
      state.selectedText = newSelectedShapes
    },
    updateSelectedStoryForEdit: (state, action) => {
      const {data} = action.payload
      const newSelectedShapes = [...state.selectedStories]
      const objIndex = newSelectedShapes.findIndex((s) => s.id === data.id)
      newSelectedShapes[objIndex] = {...newSelectedShapes[objIndex], ...data}
      state.selectedStories = newSelectedShapes
    },
    updateSelectedSettings: (state, action) => {
      const {data} = action.payload
      state.settings = data
    },
    deleteShape: (state, action) => {
      const {id, type} = action.payload
      const newSelectedStories = [...state.selectedStories]
      const newSelectedBanners = [...state.selectedBanners]
      const newSelectedBoxes = [...state.selectedBoxes]
      const newSelectedText = [...state.selectedText]

      if (type === 'story') {
        const fStories = newSelectedStories.filter((s) => s.id !== id)
        state.selectedStories = fStories

        state.showStorySettings = false
        state.selectedStoryForEdit = initialStoryWidget
      }

      if (type === 'banner') {
        const fBanners = newSelectedBanners.filter((s) => s.id !== id)
        state.selectedBanners = fBanners

        state.showBannerSettings = false
        state.selectedBannerForEdit = initialBannerWidget
      }

      if (type === 'box') {
        const fBoxes = newSelectedBoxes.filter((s) => s.id !== id)
        state.selectedBoxes = fBoxes

        state.showBoxSettings = false
        state.selectedBoxForEdit = initialBoxWidget
      }

      if (type === 'text') {
        const fText = newSelectedText.filter((s) => s.id !== id)
        state.selectedText = fText

        state.showTextSettings = false
        state.selectedTextForEdit = initialTextWidget
      }
    },
    setShowMediaModal: (state, action) => {
      const {show} = action.payload
      state.showMediaModal = show
    },
    setNestedPages: (state, action) => {
      const {data, id, level} = action.payload
      const newItem = {
        data,
        id,
        level,
      }
      const isExist = state.pageNestedList.findIndex((e: any) => e.id === id && e.level === level)
      if (isExist === -1) {
        const updatedPageNestedList = [...state.pageNestedList]
        updatedPageNestedList.push(newItem)
        state.pageNestedList = updatedPageNestedList
      }
    },
  },
})

export default pagesSlice.reducer

export const {
  setPagesList,
  setPagesFilterOptions,
  setShowManageModal,
  setShowFilterDrawer,
  setReorderedBanners,
  setShowDeleteModal,
  setSelectedPage,
  setDataForCreate,
  setNestedPages,
  updateSelectedBannerForEdit,
  setReorderedBoxes,
  setReorderedText,
  updateSelectedStoryForEdit,
  setReorderedStories,
  setSelectedShape,
  setSelectedStoryForEdit,
  updateSelectedBoxForEdit,
  updateSelectedTextForEdit,
  setSelectedBannerForEdit,
  setSelectedBoxForEdit,
  setShowMediaModal,
  setSelectedTextForEdit,
  deleteShape,
  setShowStorySettings,
  setShowBoxSettings,
  setShowTextSettings,
  resetSelectedShape,
  setShowBannerSettings,
  updateSelectedSettings,
  setShowWidgetsModal,
  setWidgetType,
} = pagesSlice.actions

export const selectPagesList = (state: IPagesObject) => state.pages.pagesList
export const selectPagesFilterOptions = (state: IPagesObject) => state.pages.pagesFilterOptions
export const selectShowManageModal = (state: IPagesObject) => state.pages.showManageModal
export const selectShowFilterDrawer = (state: IPagesObject) => state.pages.showFilterDrawer
export const selectShowDeleteModal = (state: IPagesObject) => state.pages.showDeleteModal
export const selectPage = (state: IPagesObject) => state.pages.page
export const selectPageNestedList = (state: IPagesObject) => state.pages.pageNestedList
export const selectdataForCreatePage = (state: IPagesObject) => state.pages.dataForCreate
export const selectSettings = (state: IPagesObject) => state.pages.settings
export const selectSelectedStoryForEdit = (state: IPagesObject) => state.pages.selectedStoryForEdit
export const selectSelectedBannerForEdit = (state: IPagesObject) =>
  state.pages.selectedBannerForEdit
export const selectSelectedBoxForEdit = (state: IPagesObject) => state.pages.selectedBoxForEdit
export const selectShowMediaModal = (state: IPagesObject) => state.pages.showMediaModal
export const selectShowStorySettings = (state: IPagesObject) => state.pages.showStorySettings
export const selectShowBannerSettings = (state: IPagesObject) => state.pages.showBannerSettings
export const selectShowBoxSettings = (state: IPagesObject) => state.pages.showBoxSettings
export const selectShowWidgetsModal = (state: IPagesObject) => state.pages.showWidgetsModal
export const selectWidgetType = (state: IPagesObject) => state.pages.widgetType

export const selectSelectedStories = (state: IPagesObject) => state.pages.selectedStories
export const selectSelectedBanners = (state: IPagesObject) => state.pages.selectedBanners
export const selectSelectedBoxes = (state: IPagesObject) => state.pages.selectedBoxes
export const selectSelectedText = (state: IPagesObject) => state.pages.selectedText
export const selectSelectedTextForEdit = (state: IPagesObject) => state.pages.selectedTextForEdit

export const selectShowTextSettings = (state: IPagesObject) => state.pages.showTextSettings
