// @ts-nocheck
import {
  selectSelectedBanners,
  selectSelectedBoxes,
  selectSelectedStories,
  selectSelectedText,
  selectShowBannerSettings,
  selectShowBoxSettings,
  selectShowStorySettings,
  selectShowTextSettings,
  setReorderedBanners,
  setReorderedBoxes,
  setReorderedStories,
  setReorderedText,
} from 'pages/pages/core/slice'
import {FC, useEffect, useState} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {useDispatch, useSelector} from 'react-redux'
import {reorderByIndex} from 'utils/array'
import './BannerWidget.scss'
import './BoxWidget.scss'
import './FrameSection.scss'
import SectionContainer from './SectionContainer'
import './StoryWidget.scss'

type Props = {
  lang: string
  onSectionChange: (id: number, frame: string, lang: string) => void
  frame: string
  verticalSection: any
  setVerticalSection: any
}

const Frame: FC<Props> = ({lang, onSectionChange, frame, verticalSection, setVerticalSection}) => {
  const dispatch = useDispatch()

  const selectedStories = useSelector(selectSelectedStories)
  const selectedBanners = useSelector(selectSelectedBanners)
  const selectedBoxes = useSelector(selectSelectedBoxes)
  const selectedText = useSelector(selectSelectedText)
  const showBannerSettings = useSelector(selectShowBannerSettings)
  const showStorySettings = useSelector(selectShowStorySettings)
  const showBoxSettings = useSelector(selectShowBoxSettings)
  const showTextSettings = useSelector(selectShowTextSettings)

  const [storyWidgets, setStoryWidgets] = useState<any>([])
  const [bannerWidgets, setBannerWidgets] = useState<any>([])
  const [boxWidgets, setBoxWidgets] = useState<any>([])
  const [textWidgets, setTextWidgets] = useState<any>([])
  const [activeStoryId, setActiveStoryId] = useState('')
  const [activeBannerId, setActiveBannerId] = useState('')
  const [activeBoxId, setActiveBoxId] = useState('')
  const [activeTextId, setActiveTextId] = useState('')
  const [activeSectionId, setActiveSectionId] = useState(0)
  const [selectedWidget, setSelectedWidget] = useState('story')

  useEffect(() => {
    setStoryWidgets(selectedStories)
    setBannerWidgets(selectedBanners)
    setBoxWidgets(selectedBoxes)
    setTextWidgets(selectedText)
  }, [selectedBanners, selectedBoxes, selectedStories, selectedText])

  function handleOnDragEnd(result: any) {
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    if (result.type === 'droppableItem') {
      const items = reorderByIndex(verticalSection, sourceIndex, destIndex)

      const newStoryWidgets = storyWidgets.map((story) => {
        return {
          ...story,
          section:
            story.lang === lang && story.frame === frame
              ? items.findIndex((i) => i.id === story.section)
              : story.section,
        }
      })

      const newBannerWidgets = bannerWidgets.map((banner) => {
        return {
          ...banner,
          section:
            banner.lang === lang && banner.frame === frame
              ? items.findIndex((i) => i.id === banner.section)
              : banner.section,
        }
      })

      const newBoxWidgets = boxWidgets.map((box) => {
        return {
          ...box,
          section:
            box.lang === lang && box.frame === frame
              ? items.findIndex((i) => i.id === box.section)
              : box.section,
        }
      })

      const newTextWidgets = textWidgets.map((text) => {
        return {
          ...text,
          section:
            text.lang === lang && text.frame === frame
              ? items.findIndex((i) => i.id === text.section)
              : text.section,
        }
      })

      const newItems = items.map((_, index) => {
        return {
          id: index,
        }
      })

      dispatch(setReorderedText({data: newTextWidgets}))
      dispatch(setReorderedBanners({data: newBannerWidgets}))
      dispatch(setReorderedStories({data: newStoryWidgets}))
      dispatch(setReorderedBoxes({data: newBoxWidgets}))

      setVerticalSection(newItems, frame, lang)
    } else if (result.type === 'droppableSubItem') {
      if (selectedWidget === 'story') {
        const items = reorderByIndex(storyWidgets, sourceIndex, destIndex)

        setStoryWidgets(items)
        dispatch(setReorderedStories({data: items}))
        return
      }

      if (selectedWidget === 'banner') {
        const items = reorderByIndex(bannerWidgets, sourceIndex, destIndex)

        setBannerWidgets(items)
        dispatch(setReorderedBanners({data: items}))
        return
      }

      if (selectedWidget === 'text') {
        const items = reorderByIndex(textWidgets, sourceIndex, destIndex)

        setTextWidgets(items)
        dispatch(setReorderedText({data: items}))
        return
      }

      if (selectedWidget === 'boxes') {
        const items = reorderByIndex(boxWidgets, sourceIndex, destIndex)

        setBoxWidgets(items)
        dispatch(setReorderedBoxes({data: items}))
        return
      } else return
    }
  }

  useEffect(() => {
    if (!showBannerSettings) {
      setActiveBannerId('')
    }
    if (!showStorySettings) {
      setActiveStoryId('')
    }
    if (!showBoxSettings) {
      setActiveBoxId('')
    }
    if (!showTextSettings) {
      setActiveTextId('')
    }
  }, [showBannerSettings, showBoxSettings, showStorySettings, showTextSettings])

  const getSectionItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? '#C8F5DD' : '#fff',
    ...draggableStyle,
  })

  const getSectionStyle = (isDraggingOver: any, frame: string) => ({
    width: frame === 'mobile' ? '430px' : '630px',
    background: isDraggingOver ? '#FFF8DD' : '#fff',
  })

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId='droppable' type='droppableItem'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={getSectionStyle(snapshot.isDraggingOver, frame)}>
            {verticalSection.map(({id: sectionId}, sectionIndex) => (
              <Draggable
                key={`section-${sectionId}`}
                draggableId={`section-${sectionId}`}
                index={sectionIndex}
              >
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getSectionItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      onClick={() => {
                        setActiveSectionId(sectionId)
                        onSectionChange(sectionId, frame, lang)
                      }}
                    >
                      <div className='position-relative'>
                        <span
                          {...provided.dragHandleProps}
                          className='badge badge-primary'
                          style={{
                            position: 'absolute',
                            left: '-50px',
                            top: 0,
                          }}
                        >
                          Drag
                        </span>
                        <SectionContainer
                          activeSectionId={activeSectionId}
                          sectionId={sectionId}
                          frame={frame}
                          lang={lang}
                          setSelectedWidget={setSelectedWidget}
                          setActiveStoryId={setActiveStoryId}
                          setActiveBannerId={setActiveBannerId}
                          setActiveBoxId={setActiveBoxId}
                          setActiveTextId={setActiveTextId}
                          activeTextId={activeTextId}
                          activeStoryId={activeStoryId}
                          activeBannerId={activeBannerId}
                          activeBoxId={activeBoxId}
                        />
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default Frame
