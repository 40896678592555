// @ts-nocheck
import {FC} from 'react'
import {RenderIf} from 'helpers'
import './CircleProgressIndicator.scss'

const Circle: FC = ({value, size}) => {
  const circularProgressStyle = {
    '--progress': `${value}%`,
  }
  const pct = value
  const r = size === 'sm' ? 16 : 70
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100
  return (
    <>
      <RenderIf isTrue={size === 'sm'}>
        <div
          role='progressbar'
          aria-label='Circular Progress Bar'
          style={circularProgressStyle}
          className='circular-progress size'
        />
      </RenderIf>
      <RenderIf isTrue={size === 'lg'}>
        <div role='progressbar' aria-label='Circular Progress Bar'>
          <svg width={200} height={200}>
            <g transform={`rotate(-90 ${'100 100'})`}>
              <circle
                r={r}
                cx={100}
                cy={100}
                fill='transparent'
                stroke='var(--color-neutral-fill-light-2)'
                strokeWidth='1.5rem'
                strokeDasharray={circ}
              />
              <circle
                r={r}
                cx={100}
                cy={100}
                fill='transparent'
                stroke={strokePct !== circ ? 'var(--color-success-fill-dark-1)' : ''}
                strokeWidth='1.5rem'
                strokeDasharray={circ}
                strokeDashoffset={value ? strokePct : 0}
                strokeLinecap={value ? 'round' : null}
                className='circle-lg'
              />
            </g>
          </svg>
        </div>
      </RenderIf>
    </>
  )
}

export default Circle
