import {AuthModel, UserModel} from 'models/auth.model'

const AUTH_LOCAL_STORAGE_KEY = 't-auth'
const USER_LOCAL_STORAGE_KEY = 't-user'

export const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const isValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!isValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(isValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

export const getUser = (): UserModel | undefined => {
  if (!localStorage) {
    return
  }

  const isValue: string | null = localStorage.getItem(USER_LOCAL_STORAGE_KEY)
  if (!isValue) {
    return
  }

  try {
    const user: UserModel = JSON.parse(isValue) as UserModel
    if (user) {
      return user
    }
  } catch (error) {
    console.error('USER LOCAL STORAGE PARSE ERROR', error)
  }
}

export const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const isValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, isValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

export const setUser = (user: UserModel) => {
  if (!localStorage) {
    return
  }

  try {
    const isValue = JSON.stringify(user)
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, isValue)
  } catch (error) {
    console.error('USER LOCAL STORAGE SAVE ERROR', error)
  }
}

export const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export const removeUser = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('USER LOCAL STORAGE REMOVE ERROR', error)
  }
}