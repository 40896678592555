import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {Formik} from 'formik'
import {
  deleteShape,
  selectSelectedTextForEdit,
  setShowTextSettings,
  updateSelectedTextForEdit,
} from 'pages/pages/core/slice'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

const TextSettings = () => {
  const details = useSelector(selectSelectedTextForEdit)
  const dispatch = useDispatch()
  const intl = useIntl()

  return (
    <div className='w-300px d-flex flex-column shadow-sm p-5 bg-white sticky-top'>
      <div className='d-flex justify-content-between align-items-center mt-3 text-center'>
        <button
          className='btn btn-icon btn-sm'
          onClick={() => {
            dispatch(setShowTextSettings({show: false}))
          }}
        >
          <i className='fa-solid fa-arrow-left fs-2' />
        </button>
        <h3 style={{color: '#3F4254', fontSize: '1rem'}}>{intl.formatMessage({id: 'SETTINGS'})}</h3>

        <div />
      </div>
      <div className='border-top border-gray-300 w-100 my-3' />
      <div className='mt-3 d-flex justify-content-between align-items-center'>
        <h3 style={{color: '#3D3D3D', fontSize: '1rem'}}>
          {intl.formatMessage({id: 'TEXT_WIDGET'})}
        </h3>
        <button
          className='btn btn-icon btn-danger btn-sm'
          onClick={() => {
            dispatch(deleteShape({id: details.id, type: 'text'}))
          }}
        >
          <i className='fa-solid fa-trash fs-2' />
        </button>
      </div>
      <div className='border-top border-gray-300 w-100 my-3' />
      <Formik initialValues={details} enableReinitialize onSubmit={() => {}}>
        {({values, handleSubmit, setFieldValue}) => {
          dispatch(updateSelectedTextForEdit({data: values}))
          return (
            <form className='form' onSubmit={handleSubmit}>
              <CKEditor
                editor={ClassicEditor}
                data={values.body}
                onChange={(_e: any, editor: any) => {
                  const body = editor.getData()
                  setFieldValue('body', body)
                }}
              />
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default TextSettings
