import {TContents} from 'models'

export const initialContents: TContents = [
  {
    language: 'tr',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
  {
    language: 'ar',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
  {
    language: 'en',
    name: '',
    description: '',
    slug: '',
    body: '',
    seo_headline: '',
    seo_tags: '',
  },
]
