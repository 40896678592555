import {createSlice} from '@reduxjs/toolkit'
import {IOrderTypesObject, initialOrderType, initialState} from './_models'

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderList: (state, action) => {
      const {data} = action.payload
      state.orderList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.order = initialOrderType
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
      if (!show) {
        state.order = initialOrderType
      }
    },
    setSelectedOrder: (state, action) => {
      const {data} = action.payload
      state.order = data
    },
    setOrderFilterOptions: (state, action) => {
      const {data} = action.payload
      state.ordersFilterOptions = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
  },
})

export const {
  setOrderList,
  setShowManageModal,
  setOrderFilterOptions,
  setSelectedOrder,
  setShowDeleteModal,
  setShowFilterDrawer,
} = ordersSlice.actions

export default ordersSlice.reducer

export const selectShowManageModal = (state: IOrderTypesObject) => state.orders.showManageModal
export const selectOrder = (state: IOrderTypesObject) => state.orders.order
export const selectOrderList = (state: IOrderTypesObject) => state.orders.orderList
export const selectShowDeleteModal = (state: IOrderTypesObject) => state.orders.showDeleteModal
export const selectShowFilterDrawer = (state: IOrderTypesObject) => state.orders.showFilterDrawer
export const selectOrderFilterOptions = (state: IOrderTypesObject) =>
  state.orders.ordersFilterOptions
