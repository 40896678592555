import {createSlice} from '@reduxjs/toolkit'
import {IVariationObject, initialState, initialVariation} from './_models'

const variationSlice = createSlice({
  name: 'variation',
  initialState,
  reducers: {
    setvariationList: (state, action) => {
      const {data} = action.payload
      state.variationList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.variation = initialVariation
      }
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedVariation: (state, action) => {
      const {data} = action.payload
      state.variation = data
    },
    setVariationsFilterOptions: (state, action) => {
      const {data} = action.payload
      state.variationsFilterOptions = data
    },
  },
})

export const {
  setvariationList,
  setShowManageModal,
  setSelectedVariation,
  setVariationsFilterOptions,
  setShowDeleteModal,
  setShowFilterDrawer,
} = variationSlice.actions

export default variationSlice.reducer

export const selectShowManageModal = (state: IVariationObject) => state.variation.showManageModal
export const selectVariation = (state: IVariationObject) => state.variation.variation
export const selectVariationList = (state: IVariationObject) => state.variation.variation
export const selectShowDeleteModal = (state: IVariationObject) => state.variation.showDeleteModal
export const selectShowFilterDrawer = (state: IVariationObject) => state.variation.showFilterDrawer
export const selectVariationsFilterOptions = (state: IVariationObject) =>
  state.variation.variationsFilterOptions
