import {useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useGetWidgetsQuery} from 'pages/pages/core/apiSlice'
import {
  selectWidgetType,
  setSelectedShape,
  setShowWidgetsModal,
  setWidgetType,
} from 'pages/pages/core/slice'
import Shape from './Shape'
import {SERVICE_RESPONSE_STATUS} from 'enum/service.enum'

const Widgets = ({section}: {section: {id: number; frame: string; lang: string}}) => {
  const dispatch = useDispatch()
  const widgetType = useSelector(selectWidgetType)
  const {data: fetchedData} = useGetWidgetsQuery()

  const data = useMemo(() => {
    if (fetchedData && fetchedData.status === SERVICE_RESPONSE_STATUS.SUCCESS) {
      return fetchedData.result
    }
    return []
  }, [fetchedData])

  return (
    <div className='d-flex flex-column'>
      {data?.map((item: any, index: number) => {
        if (
          item.name.toLowerCase() === widgetType ||
          (widgetType === '' &&
            (item.name === 'Story' ||
              item.name === 'Banner' ||
              item.name === 'Boxes' ||
              item.name === 'Text'))
        ) {
          return (
            <Shape
              key={index}
              data={item}
              onClick={() => {
                if (
                  item.type === 'story' ||
                  item.type === 'banner' ||
                  item.type === 'boxes' ||
                  item.type === 'text'
                ) {
                  dispatch(
                    setSelectedShape({
                      data: item.type,
                      frame: section.frame,
                      lang: section.lang,
                      section: section.id,
                    })
                  )
                  dispatch(setWidgetType({widget: ''}))
                  dispatch(setShowWidgetsModal({show: false}))
                }
              }}
            />
          )
        }
        return null
      })}
    </div>
  )
}

export default Widgets
