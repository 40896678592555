import {createSlice} from '@reduxjs/toolkit'
import {ICurrencyObject, initialCurrency, initialState} from './_models'

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    setCurrenciesList: (state, action) => {
      const {data} = action.payload
      state.currenciesList = data
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showManageModal = show
      if (!show) {
        state.currency = initialCurrency
      }
    },
    setShowDeleteModal: (state, action) => {
      const {show} = action.payload
      state.showDeleteModal = show
    },
    setSelectedCurrency: (state, action) => {
      const {data} = action.payload
      state.currency = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setCurrenciesFilterOptions: (state, action) => {
      const {data} = action.payload
      state.currenciesFilterOptions = data
    },
  },
})

export const {
  setCurrenciesList,
  setShowManageModal,
  setSelectedCurrency,
  setShowDeleteModal,
  setCurrenciesFilterOptions,
  setShowFilterDrawer,
} = currenciesSlice.actions

export default currenciesSlice.reducer

export const selectShowManageModal = (state: ICurrencyObject) => state.currencies.showManageModal
export const selectCurrency = (state: ICurrencyObject) => state.currencies.currency
export const selectCurrenciesList = (state: ICurrencyObject) => state.currencies.currenciesList
export const selectShowDeleteModal = (state: ICurrencyObject) => state.currencies.showDeleteModal
export const selectCurrenciesFilterOptions = (state: ICurrencyObject) =>
  state.currencies.currenciesFilterOptions
export const selectShowFilterDrawer = (state: ICurrencyObject) => state.currencies.showFilterDrawer
