import {createSlice} from '@reduxjs/toolkit'
import {IUsersObject, initialState, initialUser} from './_models'

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList: (state, action) => {
      const {data} = action.payload
      state.usersList = data
    },
    setSelectedUser: (state, action) => {
      const {data} = action.payload
      state.user = data
    },
    setShowFilterDrawer: (state, action) => {
      const {show} = action.payload
      state.showFilterDrawer = show
    },
    setShowManageModal: (state, action) => {
      const {show} = action.payload
      state.showMangeModal = show
      if (!show) {
        state.user = initialUser
      }
    },
    setUsersFilterOptions: (state, action) => {
      const {data} = action.payload
      state.usersFilterOptions = data
    },
  },
})

export const {
  setUsersList,
  setSelectedUser,
  setShowFilterDrawer,
  setShowManageModal,
  setUsersFilterOptions,
} = usersSlice.actions

export default usersSlice.reducer

export const selectShowFilterDrawer = (state: IUsersObject) => state.users.showFilterDrawer
export const selectShowManageModal = (state: IUsersObject) => state.users.showMangeModal
export const selectUsersList = (state: IUsersObject) => state.users.usersList
export const selectUser = (state: IUsersObject) => state.users.user
export const selectUsersFilterOptions = (state: IUsersObject) => state.users.usersFilterOptions
