import {TFilterOption} from 'models'

export type TCurrency = {
  id?: number
  name: string
  icon: string
  sign: string
  is_active: boolean
}

export const initialCurrency: TCurrency = {
  name: '',
  icon: '',
  sign: '',
  is_active: false,
}

export interface ICurrency {
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  currenciesList: Array<TCurrency>
  currency: TCurrency
  currenciesFilterOptions: Array<TFilterOption>
}

export interface ICurrencyObject {
  currencies: ICurrency
}

export const initialState: ICurrency = {
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  currenciesList: [],
  currency: initialCurrency,
  currenciesFilterOptions: [],
}
