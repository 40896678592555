export type Conditions = {
  price: string
  cart_min_total_price: string
  currency_id: string
  country_id: string
}

export type TPromotion = {
  id?: string | number
  product_color_id: string
  max_buy_count: string
  start_at: string
  end_at: string
  conditions: Array<Conditions> | []
}

export const initialPromotion: TPromotion = {
  product_color_id: '',
  max_buy_count: '',
  start_at: '',
  end_at: '',
  conditions: [
    {
      price: '',
      cart_min_total_price: '',
      currency_id: '',
      country_id: '',
    },
  ],
}

export const initialConditions: Conditions = {
  price: '',
  cart_min_total_price: '',
  currency_id: '',
  country_id: '',
}

export interface IPromotions {
  promotionsFilterOptions: Array<any>
  promotionsList: Array<TPromotion>
  promotion: TPromotion
  showFilterDrawer: boolean
  showManageModal: boolean
  showDeleteModal: boolean
  depth: {
    level: number
    url: string
  }
  promotionsLoading: boolean
  promotionNestedList: any[]
}

export interface IPromotionsObject {
  promotions: IPromotions
}

export const initialState: IPromotions = {
  // Filters
  promotionsFilterOptions: [],
  // Data
  promotion: initialPromotion,
  promotionsList: [],
  // Filter Drawer
  showFilterDrawer: false,
  // Modals
  showDeleteModal: false,
  showManageModal: false,
  // Nested
  promotionNestedList: [],
  depth: {
    level: 0,
    url: '',
  },
  promotionsLoading: false,
}
